import { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import { setCredentials } from "../app/slices/authSlice"
import { useRegisterMutation } from "../app/services/authApiSlice"

import CheckDoctorIcon from '../icons/CheckDoctorIcon'

import { USERNAME_REGEX, PASSWORD_REGEX } from "../constants/regex"

const Register = () => {
    const { screenOrientation, screenWidth } = useSelector(state => state.screenSize);
    //const { user, isLoading, isError, isSuccess, message } = useSelector(state => state.auth);

    // à éventuellement faire : faire une barre qui se remplit lors de l'attente (lorque isLoading est true)
    // à faire : désactiver (disabled=true) le bouton submit si les champs ne sont pas remplis correctement
    // ( mettre un state où il y aura une validation de chaque champ )

    const [errMessage, setErrMessage] = useState("")
    const [formContent, setFormContent] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmedPassword: "",
    })
    const { firstName, lastName, email, password, confirmedPassword } = formContent

    const register = useRegisterMutation()[0]

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const passwordRef = useRef()
    const errRef = useRef()    
    

    useEffect(() => {
        firstNameRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMessage("")
    }, [firstName, lastName, email, password, confirmedPassword])
    

    const handleChange = e => {
        const { value, name } = e.target;
        setFormContent((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(Object.values(formContent).includes("")){
            setErrMessage("Merci de remplir tous les champs.")
            return
        }
        if(!USERNAME_REGEX.test(firstName)){
            setErrMessage("Le prénom doit comporter :\n- uniquement des lettres avec ou sans accents ou des espaces. \n- entre 2 et 24 caractères.")
            firstNameRef.current.focus()
            return
        }
        if(!USERNAME_REGEX.test(lastName)){
            setErrMessage("Le nom doit comporter :\n- uniquement des lettres avec ou sans accents ou des espaces. \n- entre 2 et 24 caractères.")
            lastNameRef.current.focus()
            return
        }
        if(!PASSWORD_REGEX.test(password)){
            setErrMessage("Le mot de passe doit comporter :\n- 8 à 24 caractères.\n- au moins une lettre majuscule.\n- au moins une lettre minuscule.\n- au moins un chiffre.\n- au moins un caractère spécial.\nLes caractères spéciaux autorisés sont : &@#$%/*-+=,;:!?./§")
            passwordRef.current.focus()
            return
        }
        if(password !== confirmedPassword){
            setErrMessage("Les mots de passe ne correspondent pas.")
            passwordRef.current.focus()
            return
        }

        try {
            const userData = await register({ firstName: firstName.toLowerCase().trim(), lastName: lastName.toLowerCase().trim(), email: email.toLowerCase().trim(), password }).unwrap()
            dispatch(setCredentials({ ...userData, email: email.toLowerCase().trim() }))
            setFormContent({
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmedPassword: "",
            })
            navigate("/main")

        } catch (err) {
            if (!err?.originalStatus) {
                setErrMessage("Pas de réponse du serveur.")
            } else if (err.originalStatus === 400) {
                setErrMessage("Merci de remplir tous les champs.")
            } else if (err.originalStatus === 401) {
                setErrMessage(err.data.message)
            } else if (err.originalStatus === 409) {
                setErrMessage("Il existe déjà un utilisateur avec cet e-mail.")
            } else {
                setErrMessage("Échec.")
            }
            errRef.current.focus()
        }
    }

    const formJSX = <div>
        <form onSubmit={handleSubmit}>
            <div className="flex flex-col">
                <input 
                    className="border p-2 my-1 rounded" 
                    type="text" 
                    id="firstName" 
                    name="firstName" 
                    value={firstName} 
                    onChange={handleChange} 
                    placeholder="Prénom" 
                    ref={firstNameRef}
                />
                <input 
                    className="border p-2 my-1 rounded" 
                    type="text" 
                    id="lastName" 
                    name="lastName" 
                    value={lastName} 
                    onChange={handleChange} 
                    placeholder="Nom" 
                    ref={lastNameRef}
                />
                <input 
                    className="border p-2 my-1 rounded" 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={email} 
                    onChange={handleChange} 
                    placeholder="Adresse e-mail" 
                />
                <input 
                    className="border p-2 my-1 rounded" 
                    type="password" 
                    id="password" 
                    name="password" 
                    value={password} 
                    onChange={handleChange} 
                    placeholder="Mot de passe"
                    ref={passwordRef}
                />
                <input 
                    className="border p-2 my-1 rounded" 
                    type="password" 
                    id="confirmedPassword" 
                    name="confirmedPassword" 
                    value={confirmedPassword} 
                    onChange={handleChange} 
                    placeholder="Confirmez le mot de passe" 
                />
                <p className="first-letter:capitalize words-break text-center text-sm my-1" >
                    En cliquant sur "S'inscrire", vous déclarez avoir lu et accepté nos <Link to="/terms" className="" style={{ color: "rgb(0,150,250)" }}>Conditions d'utilisation</Link>.
                </p>

                <div ref={errRef} className={errMessage ? "errmsg px-1 text-rose-600 text-xs" : "offscreen"} aria-live="assertive">{errMessage.split("\n").map(split => <p key={split}>{split}</p>)}</div>
                <button className="border p-1 my-2 rounded bg-green-500 text-white" type="submit" >S'inscrire</button>
            </div>
            
        </form>
        
        <p className="first-letter:capitalize words-break text-center" >
            Déjà un compte ? Veuillez vous <Link to="/login" className="" style={{ color: "rgb(0,150,250)" }}>connecter</Link>.
        </p>
        
    </div> 


    if (screenWidth < 450) return (
        <div className="flex flex-col items-center h-screen">

            <div className="items-baseline gap-1 flex tracking-tight dark:text-white text-slate-900 mx-10 mt-10">
                <CheckDoctorIcon size="6xl" />
            </div>
            
            <div className="flex flex-col mx-8 my-5 content-center max-w-sm">
                <div className="my-2">
                    {formJSX}
                </div> 
            </div> 

        </div>
    )

    else if (screenOrientation === "vertical" || screenWidth < 900) return (
        <div className="flex flex-col items-center h-screen">

            <div className="items-baseline gap-1 flex tracking-tight dark:text-white text-slate-900 mx-10 mt-10">
                <CheckDoctorIcon size="7xl" />
            </div>
            
            <div className="flex flex-col mx-8 my-5 content-center w-80">
                <div className="my-2">
                    {formJSX}
                </div> 
            </div> 

        </div>
    )
    
    else return (
        <div className="flex flex-row items-center justify-center h-screen">
            
            <div className="items-baseline justify-center gap-1 flex tracking-tight dark:text-white text-slate-900 w-1/2 mx-auto">
                <CheckDoctorIcon size="8xl" />
            </div>

            <div className="flex flex-col my-5 content-center w-1/2">
                <div className="my-2 w-8/12 max-w-lg ms-10">
                    {formJSX}
                </div>
            </div>   
            
        </div>
    )
    
}

export default Register