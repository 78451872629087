import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'


import { GoTriangleDown, GoTriangleLeft } from 'react-icons/go'

import { useJoinMutation } from '../app/services/applicationsApiSlice'

import CheckDoctorIcon from '../icons/CheckDoctorIcon'


const About = () => {
    const { email } = useSelector(state => state.auth)
 
    const [showJoin, setShowJoin] = useState(false)

    const join = useJoinMutation()[0]

    // Ajouter profil type recherché (la fonction join prendra un objet plus complexe que celui actuel qui n'a que "email" comme key)
    const handleJoin = () => {
        join({ email })
    }

    return (
    <div className="bg-gray-700 min-h-screen flex items-center justify-center">
        
        <div className="bg-white rounded-lg shadow-lg p-8 w-3/4 mt-5">
            <div className="flex w-full justify-center p-5"><CheckDoctorIcon size="4xl" /></div>

            <div className="hover:bg-slate-100 rounded-md">
                <div className="py-2 mx-3 border-b">
                    <div className="flex flex-col">
                        <div className="flex flex-row flex-wrap" onClick={() => setShowJoin(!showJoin)}>
                            <p className="px-3 pt-3 pb-1 sm:p-3 text-lg text-slate-700 basis-11/12">Rejoins le projet</p>
                            <button className='basis-1/12' >{showJoin ? <GoTriangleDown className='ml-4 text-xs self-center' /> : <GoTriangleLeft className='ml-4 text-xs self-center'/>}</button>
                        </div>

                        {showJoin
                        ? <div className="flex flex-col mb-8">
                            <p className='text-md text-slate-600'>Tu voudrais participer au projet et améliorer la prise en charge des patients ?</p>
                            <div className='flex w-auto justify-center'>
                                <button onClick={handleJoin} className="m-4 py-2 px-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white border border-blue-500 hover:border-transparent rounded">
                                    Rejoins-nous
                                </button>
                            </div>
                            <p className='text-md text-slate-600'>Nous te recontacterons dans les plus brefs délais.</p>
                        </div>
                        : null}
                    </div>
                </div>
            </div>

            <div className="rounded-md">
                <div className="py-1 mx-3">
                    <div className="flex">
                        <div className="mt-5">
                            <Link to="/terms" className="px-3 pt-3 pb-1 sm:p-3 text-sm" style={{ color: "rgb(0,150,250)" }}>Conditions d'utilisation</Link>
                        </div>
                    </div>
                </div>
            </div>

           


            
        </div>
    </div>
    )
}

export default About