import { configureStore } from '@reduxjs/toolkit'

import { apiSlice } from './services/apiSlice'

import auth from './slices/authSlice'
import navbar from './slices/navbarSlice'
import record from './slices/recordSlice'
import screenSize from './slices/screenSizeSlice'
import sidebar from './slices/sidebarSlice'
import theme from './slices/themeSlice'


export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth,
    navbar,
    record,
    screenSize,
    sidebar,
    theme,
  },
  middleware: getDefaultMiddleware => 
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV === 'production' ? false : true
})