import { useEffect, useState, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useConfirmMutation, useResendMutation } from "../app/services/emailConfirmationApiSlice"

const ConfirmEmail = () => {
	const [buttonMessage, setButtonMessage] = useState("Renvoyer l'e-mail")
	const [buttonDisabled, setButtonDisabled] = useState(false)

	const effectRan = useRef(false)
	const navigate = useNavigate()

    const { token } = useParams()

    const [confirm, { isLoading, isError, isSuccess, error }] = useConfirmMutation()
	const [resend, response] = useResendMutation()
    
	useEffect(() => {
		if(effectRan.current === true || process.env.NODE_ENV !== "development"){
			confirm(token)
		}

		return () => effectRan.current = true
    }, [])

	useEffect(() => {
		if (isSuccess) navigate("/") 
	}, [isSuccess])
	

	const handleClick = (e) => {
		e.preventDefault()
		resend(error.data.email)
		setButtonDisabled(true)
		setButtonMessage("E-mail renvoyé")
	}
    
	if (isLoading) return (
		<div>Vérification en cours.</div>
	) 
	
	if (isError) return (
		<div>
			<div>{ error.data.message }</div>
			{ error?.data?.email
				? <button onClick={handleClick} disabled={buttonDisabled} className="border px-2 py-1 my-2 rounded bg-green-500 text-white" type="submit" >{buttonMessage}</button>
				: null
			}
			
		</div>
	)
}

export default ConfirmEmail