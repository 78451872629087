import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import { MdAddComment } from 'react-icons/md'
import { BsEraserFill } from 'react-icons/bs'

import { setAnswer, setUnit } from '../app/slices/recordSlice'

const InputDate = ({ question }) => {
    const { name, parent, answer, unit } = question
    
    const { questions, currentReason } = useSelector(state => state.record)
    const { currentThemeColor } = useSelector(state => state.theme)

    const currentDate = new Date()
    const timezoneOffset = currentDate.getTimezoneOffset()*60000
    const ISOCurrentDate = new Date(currentDate-timezoneOffset).toISOString().slice(0, 16)
    const unitOptions = ["précis", "approximatif"]
    const timeFrameToMs = {
        minute: 60000,
        heure: 60000*60,
        jour: 60000*60*24,
        semaine: 60000*60*24*7,
        mois: 60000*60*24*365.25/12,
        année: 60000*60*24*365.25,
    }
    const timeFrameOptions = Object.keys(timeFrameToMs)

    const [inputFocus, setInputFocus] = useState(false)
    const [date, setDate] = useState(answer ? answer[0] : "")
    const [time, setTime] = useState(0)
    const [timeFrame, setTimeFrame] = useState(timeFrameOptions[0])
    const [pre, setPre] = useState(unitOptions[0])

    const dispatch = useDispatch()
    

    useEffect(() => {
        if(date !== ""){
            console.log(date)
            dispatch(setAnswer({ name, parent, answer: [date] }))
            dispatch(setUnit({ name, parent, unit: pre }))
            const timeInterval = date ? currentDate - new Date(date) : 0
            
            for (const [index, key] of timeFrameOptions.entries()){

                const timeInUnit = timeInterval/timeFrameToMs[key]
                const timeInSuperiorUnit = timeInterval/timeFrameToMs[timeFrameOptions[index+1]]
                
                if(timeInSuperiorUnit < 1.5){
                    setTimeFrame(key)
                    setTime(Math.round(timeInUnit))
                    break
                } else {
                    setTimeFrame(key)
                    setTime(Math.round(timeInUnit))
                }
            }
        }

    }, [date])


    const handleTimingClick = () => {
        const reasonTimingRecord = questions.find(r => r.parent === currentReason && r.name === "timing")?.answer
        const reasonTimingUnit = questions.find(r => r.parent === currentReason && r.name === "timing")?.unit
        reasonTimingRecord 
            ? setDate(reasonTimingRecord)
            : setDate("")
        if (reasonTimingUnit) setUnit(reasonTimingUnit)
    }

    const handleChangeDate = e => {
        const { value } = e.target
        setDate(value)
    }

    const handleChangeNumber = e => {
        const { value } = e.target
        setTime(value)
        setDate(new Date(currentDate - value*timeFrameToMs[timeFrame] - timezoneOffset).toISOString().slice(0, 16))
    }

    const handleSelectTimeFrame = e => {
        const { value } = e.target
        setTimeFrame(value)
        setDate(new Date(currentDate - time*timeFrameToMs[value] - timezoneOffset).toISOString().slice(0, 16))
    }

    const handleSelectUnit = e => {
        const { value } = e.target
        setPre(value)
        dispatch(setUnit({ name, parent, unit: value }))
    }

    
    return (
        <div className="my-3 hover:font-medium">
            <div className="my-3" key={name}>

            {parent !== currentReason ? (
                <div className='flex flex-wrap items-center justify-start ml-1 my-5'>
                    <button 
                        className='px-1 py-0.5 flex items-center rounded-lg shadow-lg shadow-inner text-black hover:text-slate-50 bg-white hover:bg-cyan-600' 
                        onClick={handleTimingClick}
                    >
                        Utiliser les mêmes données que pour la plainte principale
                    </button>
                </div>
            ) : null}

            <div className='flex flex-wrap items-center justify-start ml-1 my-5'>
                
                <legend className="w-32 font-medium mr-5" >Date de début : </legend>
                
                <div className='flex flex-wrap m-1 w-44' >
                    <input 
                        className="min-content outline-none" 
                        style={inputFocus ? {borderBottom: `1px solid ${currentThemeColor}`} : {borderBottom: `1px solid rgb(209 213 219)`}}
                        onBlur={() => setInputFocus(false)}
                        onFocus={()=> setInputFocus(true)}
                        type="datetime-local" 
                        name={name} 
                        value={date}  
                        onChange={handleChangeDate}
                        max={ISOCurrentDate}
                    />

                </div>
            </div>


            <div className='flex flex-wrap items-center justify-start ml-1 my-5'>

                <legend className="w-32 font-medium mr-5" >Délai avant présentation :</legend>

                <div className="flex flex-wrap m-1">
                    <input 
                        className="w-16 outline-none mr-3" 
                        style={inputFocus ? {borderBottom: `1px solid ${currentThemeColor}`} : {borderBottom: `1px solid rgb(209 213 219)`}}
                        onFocus={()=> setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                        type="number" 
                        name={name} 
                        value={time} 
                        onChange={handleChangeNumber}
                        min="0"
                        max="3600"
                    />

                    {Array.isArray(timeFrameOptions) ? (
                        <select className="inline-block outline-none w-24" onChange={handleSelectTimeFrame} value={timeFrame} > 
                            {timeFrameOptions.map(u => <option value={u} key={u} >{`${u}${(time > 1 && u !== "mois") ? "s" : ""}`}</option>)}
                        </select>
                    ) : (
                        <label className="inline-block" htmlFor={name}>{timeFrameOptions}</label>
                    )}
                </div>

            </div>


            <div className='flex flex-wrap items-center justify-start ml-1 my-5'>

                <legend className="w-32 font-medium mr-5" >Précision du timing :</legend>

                <div className="flex flex-wrap m-1">
                    {unitOptions.map(option => (
                        <div className="hover:font-medium mr-5" key={option}>
                            <input 
                                className="mr-4" 
                                style={{accentColor: currentThemeColor}}
                                type="radio" 
                                name={name} 
                                id={option} 
                                value={option}
                                checked={option === unit}  
                                onChange={handleSelectUnit}
                            />
                            <label className="inline-block first-letter:capitalize self-center" htmlFor={option}>{option}</label><br/>
                        </div>
                    ))}
                </div>

            </div>

            </div>
        </div>
    )

       
}

export default InputDate