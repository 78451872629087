import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import "./index.css"
import { store } from './app/store'
import App from './App'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'

if(process.env.NODE_ENV === 'production') disableReactDevTools()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store} >
            <App />
        </Provider>
    </React.StrictMode>
);

