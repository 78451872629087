import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { MdClose, MdOutlineSearch } from "react-icons/md"
import { Tooltip } from 'react-tooltip'

import { setCurrentReason } from "../app/slices/recordSlice"
import { useGetSynonymsMutation, usePostClickMutation } from "../app/services/searchbarApiSlice"

import symptoms from "../data/symptoms/symptoms"
import Modal from "./Modal"


const Searchbar = ({ focus, setFocus }) => {
    const { currentThemeColor } = useSelector(state => state.theme)
	const { currentReason } = useSelector(state => state.record)
 
    const [searchWord, setSearchWord] = useState('')
	const [filteredReasons, setFilteredReasons] = useState([])
	const [reasonClicked, setReasonClicked] = useState([])
	const [showModal, setShowModal] = useState(false)

	const [getSynonyms, { data, isSuccess }] = useGetSynonymsMutation()
	const postClick = usePostClickMutation()[0]

	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		getSynonyms()
	}, [])

    useEffect(() => {
        setSearchWord("")
    }, [focus])

	useEffect(() => {
		if (!isSuccess){
			setFilteredReasons([{ synonym: "Chargement des plaintes...", reason: null }])
		}
		else if (searchWord.length > 0){
			const wordsArray = searchWord.split(/\W+/g);

			const filtered = data.filter(({ synonym }) => wordsArray.every(word => synonym.toLowerCase().includes(word.toLowerCase())))

			const reasonsToDisplay = filtered.slice(0, 10)
			setFilteredReasons(reasonsToDisplay)
		} 
		else {
			const reasonsToDisplay = data.slice(0, 10)
			setFilteredReasons(reasonsToDisplay)
		}
	}, [searchWord, isSuccess])

	
	const handleReasonClick = ({ synonym, reason }) => {
		navigate("/main")
		setFocus("")
		postClick(synonym)
		if(currentReason === ""){
			dispatch(setCurrentReason(reason))
		} else{
			setReasonClicked(reason)
			setShowModal(true)
		}
	}


    return (
		<div>
			{showModal ? <Modal reasonClicked={reasonClicked} setShowModal={setShowModal} /> : null}

			<div className="relative bg-white" style={{ borderRadius: "2rem", borderColor: currentThemeColor }}>
				
				<div className="relative block" data-tooltip-id="searchbar" data-tooltip-content="Recherche la plainte principale de ton patient" >
					
					<div className="flex absolute inset-y-0 left-3 items-center text-lg text-gray-700">
						{searchWord.length === 0 
							? <MdOutlineSearch />
							: <MdClose id="clearBtn" onClick={() => setSearchWord('')} />
						}
					</div>
				
					<input 
						type="text" 
						placeholder="Rechercher" 
						value={searchWord} 
						onChange={e => setSearchWord(e.target.value)} 
						className="placeholder:text-slate-400 block bg-white w-full py-2 pl-10 pr-3 sm:text-sm focus:outline-none focus:border-1 hover:border-1" 
						style={{ borderRadius: "2rem", borderColor: currentThemeColor }}
						onFocus={() => setFocus("searchbar")}
					/>

				</div>
				<Tooltip id="searchbar" place="top" effect="solid" />

				{focus === "searchbar" && filteredReasons.length > 0 
				? <div className="absolute bg-white w-full sm:text-sm mt-1 p-3" style={{ borderRadius: "1.25rem" }}>
					{filteredReasons.map(({ synonym, reason }) => (
							<div 
								key={synonym}
								name={synonym}
								onClick={() => handleReasonClick({ synonym, reason })}
								className={"px-1 sm:px-7 py-2 sm:py-1 text-sm sm:text-sm text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray"}
								style={{ borderRadius: "2rem" }}
							>
								<p>{synonym.charAt(0).toUpperCase() + synonym.slice(1)}</p>
							</div> 
							
						))}
				</div> 
				: null}
			</div>
		</div>
    )
}


export default Searchbar