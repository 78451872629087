import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { useGetReportMutation } from "../app/services/textApiSlice.js"
import { useSubmitMutation, useGetLogMutation } from "../app/services/submitApiSlice.js"

import { CgSpinner } from "react-icons/cg"



const Text = () => {
    const [sendButton, setSendButton] = useState("Envoyer le rapport par e-mail")
    const [copyButton, setCopyButton] = useState("Copier le rapport")
    const [report, setReport] = useState("")

    const { email, firstName } = useSelector(state => state.auth)
    const { currentReason, questions } = useSelector(state => state.record)

    const [getReport, { data: reportData, isSuccess }] = useGetReportMutation({ fixedCacheKey: 'shared-report' })
    const submit = useSubmitMutation()[0]
    const { data: logData } = useGetLogMutation({ fixedCacheKey: 'shared-log' })[1]

    const answeredQuestions = questions.filter(({ answer, comment }) => (answer && answer[0] && answer[0] !== "") || comment)

    const answersAlreadyInDatabase = logData
        ? logData.questions.every(({ name }) => answeredQuestions.find(question => question.name === name)) 
            && answeredQuestions.every(({ name }) => logData.questions.find(question => question.name === name))
        : false

    
    useEffect(() => {
        if(!answersAlreadyInDatabase){
            getReport({ state: answeredQuestions, currentReason })
        }
    }, [])

    useEffect(() => {
        if(!answersAlreadyInDatabase){
            setReport(reportData)
        } else {
            setReport(logData.text)
        }
    }, [isSuccess, logData])

    const sendText = () => {
        if(isSuccess || logData){

            if(!answersAlreadyInDatabase){
                submit({ email, firstName, questions: answeredQuestions, currentReason, sendMail: "text" })
                setSendButton("E-mail envoyé !")
            }  
        }
    }

    const handleCopy = () => {
        if(isSuccess || logData){
            
            if(!answersAlreadyInDatabase && copyButton !== "Rapport copié !"){
                submit({ email, firstName, questions: answeredQuestions, currentReason, sendMail: false })
            }

            navigator.clipboard.writeText(report)
            setCopyButton("Rapport copié !")
        }
    } 


    /*const loadingState = <div class="animate-pulse flex space-x-4">
            <div class="flex-1 space-y-6 py-1">
                <div class="h-6 bg-slate-300 rounded"></div>
                <div class="space-y-3">
                    <div class="grid grid-cols-3 gap-4">
                        <div class="h-5 bg-slate-300 rounded col-span-2"></div>
                        <div class="h-5 bg-slate-300 rounded col-span-1"></div>
                        <div class="h-5 bg-slate-300 rounded col-span-1"></div>
                        <div class="h-5 bg-slate-300 rounded col-span-2"></div>
                        <div class="h-5 bg-slate-300 rounded col-span-1"></div>
                        <div class="h-5 bg-slate-300 rounded col-span-1"></div>
                    </div>
                    <div class="h-5 bg-slate-300 rounded"></div>
                    <div class="h-5 bg-slate-300 rounded"></div>
                    <div class="h-5 bg-slate-300 rounded"></div>
                </div>
            </div>
        </div>*/

    const loadingState = <div className="">
            <div className="w-full flex justify-center my-5">Génération du rapport...</div>
            <div className="w-full flex justify-center my-5"><CgSpinner size={30} className="animate-spin" /></div>    
        </div> 
    
    return (
        <div>
            <div className='w-full h-full p-8 bg-white border-2 shadow hover:shadow-lg rounded-md overflow-auto select-none'>
                {isSuccess || answersAlreadyInDatabase
                    ? report?.split("\n")
                        .map((paragraph, index) => 
                            paragraph === "" 
                                ?   <br key={index} />
                                :   <p className="first-letter:capitalize mb-1" key={index}>
                                        {paragraph}
                                    </p>
                            )
                    : <div>{loadingState}</div>
                }
            </div>

            <div className="flex gap-4">
                <button 
                    className="border bg-white px-4 py-3 rounded-lg font-medium bg-sky-50 text-blue-900 my-4 shadow-lg hover:shadow-xl w-80" 
                    onClick={sendText}
                    style={{ display: answersAlreadyInDatabase ? "none" : "block" }}
                    disabled={sendButton === "E-mail envoyé !"}
                >
                    {sendButton}
                </button>

                <button 
                    className="border bg-white px-4 py-3 rounded-lg font-medium bg-sky-50 text-blue-900 my-4 shadow-lg hover:shadow-xl w-80" 
                    onClick={handleCopy}
                >
                    {copyButton}
                </button>
            </div>
            
        </div>
    )


}

export default Text