import { useState, useMemo, createRef, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"

import { GoTriangleDown, GoTriangleLeft } from 'react-icons/go'
import { IoMenuOutline } from "react-icons/io5"
import { Tooltip } from 'react-tooltip'


import { setAnswer, eraseRecordElement, addReason } from '../app/slices/recordSlice'

import Input from './Input'


const Questions = ({ reasons }) => {

    const { questions, currentReason } = useSelector(state => state.record)
    const [currentSection, setCurrentSection] = useState(null)
    const [openedCategories, setOpenedCategories] = useState({})
    const [reasonsSorted, setReasonsSorted] = useState([])
    const [openMenu, setOpenMenu] = useState(true)

    const reasonsArray = questions
        ?.filter(({ name }) => reasons?.map(({ reason }) => reason).includes(name)) // selects only the questions which are reasons
        .map(q => q.name)   // and then only keeps the names of those reasons

    const sex = questions.find(({ name }) => name === "sexe")?.answer ? questions.find(({ name }) => name === "sexe")?.answer[0] : undefined
    
    const dispatch = useDispatch()

    const qRefs = useMemo(() => {
        if (questions) {
            return questions.map((q) => {
                return { ...q, ref: createRef() }
            })
        }
        return []
    }, [questions])


    const orderCategories = () => {
        const categoriesObj = {}
        const currentCategory = reasons?.find(({ reason }) => reason === currentReason).category
        
        let order
        switch (currentCategory) {
            case "abdomen":
                order = ["abdomen", "appareil génital féminin", "appareil génital masculin", "général", "membres inférieurs"]
                break
            case "appareil génital masculin":
                order = ["appareil génital masculin", "abdomen", "général", "membres inférieurs"]
                break
            case "appareil génital féminin":
                order = ["appareil génital féminin", "abdomen", "général", "membres inférieurs"]
                break
            case "membres inférieurs":
                order = ["membres inférieurs", "abdomen", "appareil génital féminin", "appareil génital masculin", "général"]
                break
            default:
                order = ["général", "abdomen", "appareil génital féminin", "appareil génital masculin", "membres inférieurs"]
        }

        order.forEach((category) => categoriesObj[category] = false)
        categoriesObj[currentCategory] = true
        
        return categoriesObj
    }

    useEffect(() => {
        const categoriesObj = orderCategories()

        if (sex === "féminin") delete categoriesObj["appareil génital masculin"]
        else if (sex === "masculin") delete categoriesObj["appareil génital féminin"]

        setOpenedCategories(categoriesObj)
    }, [sex])

    useEffect(() => {
        if(reasons){
            const categoriesObj = orderCategories()
    
            setOpenedCategories(categoriesObj)
        }
        
    }, [reasons])
    

    useEffect(() => {
        if (reasons){
            const reasonsSorting = [...reasons]
            
            reasonsSorting.sort((a, b) => {
                const indexA = reasonsArray.indexOf(a.reason)
                const indexB = reasonsArray.indexOf(b.reason)
                if(indexA === -1) return 1
                if(indexB === -1) return -1
                else return indexA - indexB
            })

            setReasonsSorted(reasonsSorting)

        }
    }, [reasons, questions.length])
    
    
    
    const scrollToClick = (name) => {
        if(reasonsArray.includes(name)){
            const qRefIndex = qRefs.findIndex((q) => q.name === name)
            qRefs[qRefIndex].ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        } 
    }
    

    const handleScroll = () => {
        const offset = 60

        // Recherchez la section actuellement visible
        const visibleSection = qRefs.find((q) => {
            const element = q.ref.current
            if (element) {
                const rect = element.getBoundingClientRect()
                return rect.top - offset <= 0 && rect.bottom - offset > 0
            }
            return false
        })

        // Mettez à jour l'état avec la section actuellement visible
        if (visibleSection && reasonsArray.includes(visibleSection.name)) {
            setCurrentSection(visibleSection.name)
        } else if (visibleSection && visibleSection.parent) {
            setCurrentSection(visibleSection.parent)
        }
    }

    const handleClick = (reason, response) => {
        if(response === "erase"){
            dispatch(eraseRecordElement({ name: reason }))
        } else {
            if (response === "oui"){
                dispatch(addReason({ name: reason }))
                dispatch(setAnswer({ name: reason, answer: ["oui"]}))
            } else if (response === "non"){
                dispatch(addReason({ name: reason }))
                dispatch(setAnswer({ name: reason, answer: ["non"]}))
            }
        } 
    }
    

    return (
        <div className="flex max-h-full overflow-auto overscroll-contain pb-14 w-full" onScroll={handleScroll}>
            <div className={openMenu 
                ? "h-full bg-slate-50 fixed overflow-auto overscroll-contain shadow-xl w-72 md:w-96"
                : "h-full bg-slate-50 fixed overscroll-contain shadow-xl py-4 w-10 md:w-14 overflow-visible"
            }>
                <div className='pb-16'>
                    <div 
                        className='w-full mb-4 px-1 md:px-3 flex justify-between items-center sticky top-0 bg-slate-50 pb-2 pt-1 sm:pt-2 md:pt-4'
                        onClick={() => setOpenMenu(!openMenu)}
                    >
                        {openMenu ? <p className='font-semibold text-lg'>Menu</p> : null}
                        <button 
                            data-tooltip-id="toggleMenu" 
                            data-tooltip-content={openMenu ? "Fermer le menu" : "Ouvrir le menu"} 
                        >
                            <IoMenuOutline size={30}/>
                        </button>
                        <Tooltip id="toggleMenu" place="top" effect="solid"/>
                    </div>
                    {openMenu && Object.keys(openedCategories).map(category => <div key={category} className='w-full mb-5'>
                        <div 
                            className='flex items-center w-full mx-1 sm:mx-2 px-1.5 sm:px-4 sm:mb-1 py-1 w-11/12 text-start bg-slate-50 text-slate-700 rounded-sm text-sm sm:text-base font-small sm:font-normal hover:shadow-lg'
                            onClick={() => setOpenedCategories({...openedCategories, [category]: !openedCategories[category]})}
                        >
                            <div className='w-3/4 font-semibold'>{category.charAt(0).toUpperCase() + category.slice(1)}</div>
                            {openedCategories[category] 
                            ? <div className='w-1/4 flex justify-end'><GoTriangleLeft/></div> 
                            : <div className='w-1/4 flex justify-end'><GoTriangleDown/></div>}
                        </div>

                        {openedCategories[category] ?
                            reasonsSorted
                            .filter(reason => reason.category === category)
                            .map(({ reason }) => 
                                <div 
                                    key={reason}
                                    className={reason === currentSection 
                                        ? "flex items-center w-full mx-1 sm:mx-2 px-1 sm:px-2 sm:my-2 py-1 w-28 w-11/12 bg-white text-slate-700 text-sm sm:text-base font-small sm:font-normal border rounded-md shadow hover:shadow-xl" 
                                        : "flex items-center w-full mx-1 sm:mx-2 px-1 sm:px-2 sm:mb-1 py-1 w-28 w-11/12 text-start bg-slate-50 text-slate-700 rounded-sm text-sm sm:text-base font-small sm:font-normal hover:shadow-lg"}
                                >
                                    <button 
                                        className="text-left items-center w-3/4 pl-4"
                                        onClick={() => scrollToClick(reason)}
                                    >
                                        {reason.charAt(0).toUpperCase() + reason.slice(1)}
                                    </button>
                                    
                                    <div className="flex items-center w-1/4 text-xs">
                                        <button 
                                            className='w-2/5 p-1 border rounded text-slate-800 hover:text-slate-400 text-center hidden md:block'
                                            onClick={() => handleClick(reason, "oui")}
                                            style={{ 
                                                backgroundColor : questions.find(({ name, answer }) => name === reason && answer && answer[0] === "oui") ? "rgb(203 213 225)" : "white"
                                            }}
                                        >
                                            Oui
                                        </button>
                                        <button 
                                            className='w-2/5 p-1 border rounded text-slate-800 hover:text-slate-400 text-center hidden md:block'
                                            onClick={() => handleClick(reason, "non")}
                                            style={{ 
                                                backgroundColor : questions.find(({ name, answer }) => name === reason && answer && answer[0] === "non") ? "rgb(203 213 225)" : "white"
                                            }}
                                        >
                                            Non
                                        </button>
                                        <button 
                                            className='w-2/5 p-1 border rounded text-slate-800 hover:text-slate-400 text-center block md:hidden'
                                            onClick={() => handleClick(reason, "oui")}
                                            style={{ 
                                                backgroundColor : questions.find(({ name, answer }) => name === reason && answer && answer[0] === "oui") ? "rgb(203 213 225)" : "white"
                                            }}
                                        >
                                            O
                                        </button>
                                        <button 
                                            className='w-2/5 p-1 border rounded text-slate-800 hover:text-slate-400 text-center block md:hidden'
                                            onClick={() => handleClick(reason, "non")}
                                            style={{ 
                                                backgroundColor : questions.find(({ name, answer }) => name === reason && answer && answer[0] === "non") ? "rgb(203 213 225)" : "white"
                                            }}
                                        >
                                            N
                                        </button>
                                        <button 
                                            className='w-1/5 p-1 font-bold text-slate-800 hover:text-slate-400 rounded-full text-center'
                                            onClick={() => handleClick(reason, "erase")}
                                        >
                                            x
                                        </button>
                                    </div>
                                </div>
                            ) 
                            : null
                        }
                        
                    </div> )}
                </div>
            </div>
            <div className='flex ml-10 lg:mx-52 xl:mx-96 w-full justify-center'>
                <div className='lg:w-[44rem]'>
                    <div className='m-3 p-8 bg-white rounded-md border-2 shadow hover:shadow-lg flex justify-between xl:justify-center' >
                        <h1 className='font-semibold first-letter:capitalize text-2xl'>{currentReason}</h1>
                    </div>

                    <div>   
                        {questions?.length > 0 
                            ? questions.map((question, index) => <div key={index} ref={qRefs[index].ref}><Input question={question} key={index} /></div>)
                            : null
                        }
                    </div>
                </div>
                
                
            </div>

        </div>
    )
}

export default Questions