import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setAnswer, eraseRecordElement } from '../app/slices/recordSlice'

const InputText = ({ question }) => {
    const { name, parent, answer, placeholder } = question
    
    const { currentThemeColor } = useSelector(state => state.theme)

    const [textAreaHeight, setTextAreaHeight] = useState(1)
    const [inputFocus, setInputFocus] = useState(false)
    const [userResponse, setUserResponse] = useState(answer ? answer[0] : "")
    
    const dispatch = useDispatch()

    const handleInputChange = e => {
        const { scrollHeight, rows, value } = e.target
        const rowHeight = 24
        const trows = Math.ceil(scrollHeight / rowHeight)
    
        if (trows > rows) { 
            setTextAreaHeight(trows)
        } 
        setUserResponse(value)
        
        if(value === "") dispatch(eraseRecordElement({ name, parent }))
        else dispatch(setAnswer({ name, parent, answer: [value] }))
    } 

    useEffect(() => {
        if(answer?.length === 0 || (answer && answer[0] === "")){
            setUserResponse("")
        } 
    }, [answer])

    
    
    return (
        <div className="my-3 hover:font-medium">
            <textarea 
                className="px-1 ml-1 mr-4 w-full outline-none" 
                style={inputFocus ? {borderBottom: `1px solid ${currentThemeColor}`, minHeight: 24, resize: "none", overflow: 'hidden'} : {borderBottom: `1px solid rgb(209 213 219)`, minHeight: 24, resize: "none", overflow: 'hidden'}}
                onFocus={()=> setInputFocus(true)}
                onBlur={() => setInputFocus(false)}
                type="text" 
                name={name} 
                value={userResponse}  
                onChange={handleInputChange}
                rows={textAreaHeight}
                placeholder={placeholder ? `Ex : ${placeholder}` : "Écris tes commentaires"}            
            />
        </div>
    )

       
}

export default InputText