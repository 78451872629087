import { apiSlice } from "./apiSlice"

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        changeFirstName: builder.mutation({
            query: body => ({
                url: "/user/firstName",
                method: "PUT",
                body
            })
        }),
        changeLastName: builder.mutation({
            query: body => ({
                url: "/user/lastName",
                method: "PUT",
                body
            })
        }),
        changePassword: builder.mutation({
            query: body => ({
                url: "/user/password",
                method: "PUT",
                body
            })
        }),
        sendResetPasswordEmail: builder.mutation({
            query:  body => ({
                url: "/reset_password/send_reset_password_email",
                method: "POST",
                body
            })
        }),
        resetPassword: builder.mutation({
            query:  body => ({
                url: "/reset_password/reset_password",
                method: "POST",
                body
            })
        })
    })
})

export const { useChangeFirstNameMutation, useChangeLastNameMutation, useChangePasswordMutation, useSendResetPasswordEmailMutation, useResetPasswordMutation } = userApiSlice