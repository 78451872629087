import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'

import { FaRegComment } from "react-icons/fa6"
import { BsEraserFill } from 'react-icons/bs'

import { eraseRecordElement, setComment } from '../app/slices/recordSlice'
import InputDate from './InputDate'
import InputText from './InputText'
import InputRadioCheckbox from './InputRadioCheckbox'
import InputNumber from './InputNumber'

const QuestionInput = ({ question }) => {
    const { name, type, parent, answer } = question
    
    const { questions } = useSelector(state => state.record)
    const { screenOrientation } = useSelector(state => state.screenSize)
    const { currentThemeColor } = useSelector(state => state.theme)

    
    const [cardFocus, setCardFocus] = useState(false)
    const [showComment, setShowComment] = useState(false)
    const [commentFocus, setCommentFocus] = useState(false)
    const [textAreaHeight, setTextAreaHeight] = useState(1)

    const comment = questions.find(r => r.name === name && r.parent === parent)?.comment

    const dispatch = useDispatch()    

    const handleCommentChange = e => {
        const { scrollHeight, rows, value } = e.target
        const rowHeight = 24
        const trows = Math.ceil(scrollHeight / rowHeight)
    
        if (trows > rows) { 
            setTextAreaHeight(trows)
        } 

        dispatch(setComment({ name, parent, comment: value }))
        
    } 
    
    

    const renderInputField = () => {
        switch (type) {
            case 'checkbox':
            case 'radio':
            case 'bool':
                return <InputRadioCheckbox question={question} />

            case 'number':
                return <InputNumber question={question} />

            case 'text':
                return <InputText question={question} />

            case 'date':
                return <InputDate question={question} />

            default:
                return null
            }
    }

    return (
        <div 
            className='m-4 p-6 bg-white rounded-md border shadow hover:shadow-lg xl:px-12 transition-colors'
            style={{ 
                backgroundColor: answer?.length > 0 ? "rgb(220 252 231)" : "white", 
                borderWidth: answer?.length > 0 ? 2 : 1,
            }}
            onMouseEnter={()=> setCardFocus(true)}
            onMouseLeave={() => setCardFocus(false)}  
        >
            <div className='flex justify-between'>
                <legend className="font-semibold first-letter:capitalize" >{name}</legend>   
                {(cardFocus || screenOrientation === "vertical") ? (
                    <div className='flex' >
                        
                        <button data-tooltip-id="comment" data-tooltip-content="Ajouter un commentaire" type="button" className='mx-2' onClick={() => setShowComment(!showComment)} >
                            <FaRegComment />
                        </button>
                        <Tooltip id="comment" place="top" effect="solid"/>

                        <button data-tooltip-id="clear" data-tooltip-content="Effacer" className='mx-2' onClick={() => dispatch(eraseRecordElement({ name, parent }))} >
                            <BsEraserFill />
                        </button>
                        <Tooltip id="clear" place="top" effect="solid"/>

                    </div>
                    ) : null
                }
            </div>

            {renderInputField()}

            {type !== "text" && showComment 
                ? <div className="my-3 hover:font-medium flex" key={name}>

                    <legend className="first-letter:capitalize min-w-max" >Commentaire :</legend>
                    <textarea 
                        className="px-1 ml-2 mr-4 outline-none w-full" 
                        style={commentFocus ? {borderBottom: `1px solid ${currentThemeColor}`, minHeight: 24, resize: "none", overflow: 'hidden'} : {borderBottom: `1px solid rgb(209 213 219)`, minHeight: 24, resize: "none", overflow: 'hidden'}}
                        onFocus={()=> setCommentFocus(true)}
                        onBlur={() => setCommentFocus(false)}
                        type="text" 
                        name={name} 
                        value={comment || ''}  
                        onChange={handleCommentChange}
                        rows={textAreaHeight}
                    />

                </div>
                : null
            }
        </div>
    )
}

export default QuestionInput