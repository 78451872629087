import { apiSlice } from "./apiSlice"

export const submitApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        /*submit: builder.mutation({
            query: data => ({
                url: "/form_submission",
                method: "POST",
                body: data
            })
        }),*/
        getLog: builder.mutation({
            query: ({ id }) => ({
                url: `/form_submission/get_text/${id}`,
                method: "GET",
            })
        }),
        submit: builder.mutation({
            query: data => ({
                url: `/form_submission`,
                method: "POST",
                body: data
            })
        }),
    })
})

export const { useSubmitMutation, useGetLogMutation } = submitApiSlice