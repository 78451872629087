const unitAccorded = (response, unit) => {
    const condition = (
        response > 1 
        && !["s", "x", "z"].includes(unit.slice(-1)) 
        && unit.charAt(0) !== "/" 
        && !unit.includes("par")
        && !unit.includes("fois")
    )
    return condition ? `${unit}s` : unit
}

export default unitAccorded