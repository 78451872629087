import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import { useSendFbMutation } from '../app/services/feedbackApiSlice'

const Support = () => {
    const { currentThemeColor } = useSelector(state => state.theme)
    const { email } = useSelector(state => state.auth)

    const [feedback, setFeedback] = useState('')

    const [sendFb, { isError, isSuccess, isUninitialized }] = useSendFbMutation()

    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess){
            setTimeout(() => {
                navigate('/')
            }, 7000)
        }
    }, [isSuccess])


    const handleSubmit = (e) => {
        e.preventDefault()
		sendFb({ email, type: "support", feedback })
    }

    return (
        <div className="bg-gray-700 min-h-screen flex items-center justify-center">
			<div className="bg-white rounded-lg shadow-lg p-8 w-1/2">
			{!isSuccess ? (
				<>
				<h1 className="text-5xl font-bold text-gray-900 mb-8 text-center">
					Comment pouvons-nous <span style={{ color: currentThemeColor }} >t'aider</span> ?
				</h1>
				<form onSubmit={handleSubmit} className='flex flex-col'>
					<div className="flex flex-col mb-6">
					<textarea
						id="feedback"
						className="mt-2 p-4 w-full h-40 border border-gray-300 rounded"
						value={feedback}
						onChange={(e) => setFeedback(e.target.value)}
						placeholder={`As-tu rencontré une erreur ou des difficultés pour naviguer sur le site ou trouver des informations spécifiques ?\nAs-tu d'autres questions ?`}
					/>
					</div>
					<button
					className="py-3 px-6 text-white font-bold rounded hover:bg-pink-600 transition duration-300 opacity-75 disabled:opacity-50 hover:opacity-100 w-1/2 self-center"
					style={{ backgroundColor: currentThemeColor }}
					type="submit"
					disabled={feedback.length === 0}
					>
						Envoyer
					</button>
					<p className='self-center mt-3'>Ou<Link to="/feedback" className="ml-1" style={{ color: "rgb(0,150,250)" }}>aide-nous à nous améliorer !</Link></p>
					{isError
                        ? <p className='text-center self-center mt-3 text-red-600'>Une erreur est survenue lors de l'envoi de ta requête. Contacte-nous plus tard.</p>
                        : null
                    }
				</form>
				</>
			) : (
				<div className="text-center">
				<h1 className="text-5xl font-bold text-gray-900 mb-8">
					Merci pour ton feedback ! 
				</h1>
				<p className="text-lg text-gray-900">
					Nous reviendrons vers toi dans les plus brefs délais.<br/>
					Tu vas être redirigé vers la page d'accueil.<br/>
					N'hésite pas à remplir à nouveau ce formulaire si tu veux nous faire part d'autres commentaires.
				</p>
				</div>
			)}
			</div>
      </div>
    )
}
        




/*Aide-nous à nous améliorer == lien vers page feedback <br/>
        
As-tu rencontré une erreur ou des difficultés pour naviguer sur le site ou trouver des informations spécifiques ? 
Autres questions

Page feedback : 
Donne-nous ton avis
    Quelle a été ton impression générale du site
    Comment as-tu entendu parler de Check.doctor ?
    Tes attentes ont été satisfaites, non satisfaites ou dépassées ? 
Raconte-nous ce que tu as aimé
    Qu'est-ce que tu as trouvé le plus utile
    Quelle est selon toi la fonctionnalité la plus importante du site
    Y a-t-il quelque chose qui t'a particulièrement plu ?
Suggère-nous des améliorations
    Quelles fonctionnalités ou contenu aimerais-tu voir apparaître prochainement ? 
    Comment tu améliorerais la convivialité du site ?*/

export default Support