import { Link } from 'react-router-dom'

import CheckDoctorIcon from '../icons/CheckDoctorIcon'

const Terms = () => { 

    return (
    <div className="bg-gray-700 min-h-screen flex items-center justify-center">
        
        <div className="bg-white rounded-lg shadow-lg p-8 w-3/4 mt-5">
            <div className="flex w-full justify-center p-5"><CheckDoctorIcon size="4xl" /></div>

            <div className="rounded-md" >
                <div className="py-1 mx-3">
                    <div className="flex flex-col">
                        <div className="flex flex-row flex-wrap my-10">
                            <p className="px-3 pt-3 pb-1 sm:p-3 text-xl text-slate-700 basis-11/12">Conditions d'utilisation</p>
                        </div>
                        
                        <div className="">
                            <p className='text-sm text-slate-500 mb-8 mb-14'>Bienvenue sur check.doctor ! Avant d'utiliser nos services, veuillez lire attentivement les présentes conditions d'utilisation. En accédant à ce site et en l'utilisant, vous acceptez d'être lié par ces conditions. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre site.</p>
                            
                            <h1 className='text-md text-slate-600 my-3'>Utilisation du site</h1>
                            <p className='text-sm text-slate-500 mb-8'>
                                Ce site internet a été conçu pour servir le médecin à effectuer des examens et des interrogatoires plus complets et l'aider à rédiger ses rapports de façon la plus exhaustive, rapide et aisée possible. 
                                <br/>Il peut aider le clinicien dans sa démarche pour prodiguer de meilleurs soins aux patients qu'il prend en charge.
                                <br/>Le contenu de ce site ne remplace pas des connaissances médicales approfondies.
                                <br/>Il ne se substitue pas au jugement clinique du médecin.
                            </p>

                            <h1 className='text-md text-slate-600 my-3'>Responsabilité</h1>
                            <p className='text-sm text-slate-500 mb-8'>
                                Bien que le site ait été créé avec les meilleures intentions et que nous nous efforcions de fournir des informations précises et à jour, nous ne garantissons pas l'exactitude, l'exhaustivité ou la fiabilité des informations présentes sur notre site. L'utilisateur reconnaît que l'utilisation des informations fournies relève de sa propre responsabilité.
                                <br/>Il est de la responsabilité de l'utilisateur de relire et vérifier l'exactitude des rapports rédigés automatiquement à l'aide du site.
                                <br/>Les auteurs du site ne peuvent être tenus responsables des dommages directs, indirects, spéciaux, consécutifs ou punitifs découlant de l'utilisation du site ou de l'incapacité à l'utiliser, y compris les pertes de données, les interruptions d'activité, les pertes de revenus ou tout autre dommage.
                            </p>
                            <h1 className='text-md text-slate-600 my-3'>Collecte des données</h1>
                            <p className='text-sm text-slate-500 mb-8'>
                                Nous ne collectons aucune donnée personnelle permettant l'identification des patients. 
                                <br/>Aucune donnée personnelle des patients n'est demandée sur le site ni ne doit être encodée par l'utilisateur lors de son utilisation.
                            </p>
                            <h1 className='text-md text-slate-600 my-3'>Sécurité des données</h1>
                            <p className='text-sm text-slate-500 mb-8'>
                                Nous nous efforçons de prendre des mesures de sécurité appropriées pour protéger les informations que vous nous fournissez contre tout accès non autorisé, toute divulgation ou toute destruction. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée.
                            </p>

                            <h1 className='text-md text-slate-600 my-3'>Cookies</h1>
                            <p className='text-sm text-slate-500 mb-8'>
                                Notre site utilise des cookies essentiels au fonctionnement du site, qui sont nécessaires pour vous permettre d'utiliser nos services de manière optimale. Ces cookies peuvent inclure des identifiants uniques pour reconnaître votre navigateur lors de vos visites ultérieures et pour vous fournir une expérience personnalisée. Ces cookies ne collectent pas d'information personnelle identifiable et ne sont pas utilisés à des fins de marketing.
                                <br/>Vous avez la possibilité de configurer votre navigateur pour bloquer ces cookies ou pour vous avertir lorsque ces cookies sont utilisés. Cependant, veuillez noter que cela pourrait affecter le fonctionnement correct de notre site et limiter votre utilisation de nos services.
                            </p>

                            <h1 className='text-md text-slate-600 my-3'>Modification et résiliation</h1>
                            <p className='text-sm text-slate-500 mb-8'>
                                Les auteurs du site se réservent le droit de modifier, de suspendre ou de résilier tout ou partie du site, y compris les conditions d'utilisation, à tout moment et sans préavis. Il est de la responsabilité de l'utilisateur de vérifier régulièrement les mises à jour de ces conditions.
                            </p>

                            <h1 className='text-md text-slate-600 my-3'>Contact</h1>
                            <p className='text-sm text-slate-500 mb-8'>
                                Si vous avez des questions concernant ces conditions d'utilisation, veuillez nous contacter à l'adresse mail suivante : <Link to={`mailto:support@check.doctor?subject=${encodeURIComponent("Question concernant les conditions d'utilisation")}`} style={{ color: "rgb(0,150,250)" }}>support@check.doctor</Link>.
                            </p>                            
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className='mt-8'>
                <Link to="/" className="px-3 pt-3 pb-1 sm:p-3 text-sm" style={{ color: "rgb(0,150,250)" }}>Retour à la page d'accueil</Link>
            </div>
        </div>
    </div>
    )
}

export default Terms