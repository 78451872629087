import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import unitAccorded from "../functions/unitAccorded"
import capitalizeFirstLetter from "../functions/capitalizeFirstLetter"

import { useSubmitMutation, useGetLogMutation } from "../app/services/submitApiSlice.js"



const Table = () => {
    const { currentReason, questions } = useSelector(state => state.record)
    const { email, firstName } = useSelector(state => state.auth)

    const [sendButton, setSendButton] = useState("Envoyer le texte par e-mail")
    const [copyButton, setCopyButton] = useState("Copier le texte")
    const [answeredQuestions, setAnsweredQuestions] = useState([])

    const { data: logData } = useGetLogMutation({ fixedCacheKey: 'shared-log' })[1]
    const [submit] = useSubmitMutation()

    const answersHaveChangedFromDatabase = logData?.length !== answeredQuestions.length

    useEffect(() => {
        const state = []
        questions.forEach(({ name, parent, answer, unit, comment }) => {
            if ((answer && answer[0] && answer[0] !== "") || comment){
                state.push({ name, parent, answer, unit, comment })
            }
        })
        setAnsweredQuestions(state)
    }, [])

    const sendText = () => {
        if(!logData || answersHaveChangedFromDatabase){    
            submit({ email, firstName, questions: answeredQuestions, currentReason, sendMail: "table" })
            setSendButton("E-mail envoyé !")
        }
    }

    const handleCopy = () => {
        if(copyButton !== "Texte copié !" && (!logData || answersHaveChangedFromDatabase)){
            submit({ email, firstName, questions: answeredQuestions, currentReason, sendMail: false })
        }

        
        const report = answeredQuestions.map(({ parent, name, answer, comment, unit }, index) => {
            const rows = [
                parent ? `${capitalizeFirstLetter(parent)} : ${name}`: `${capitalizeFirstLetter(name)}`,
                answer.map(a => unit ? `- ${capitalizeFirstLetter(a)} ${unitAccorded(a, unit)}` : `- ${capitalizeFirstLetter(a)}`).join('\n')
            ]
            
            if(comment) rows.push(`- ${capitalizeFirstLetter(comment)}`)
            
            return rows.join("\n")
        }).join("\n\n")
        
        navigator.clipboard.writeText(report)
        setCopyButton("Texte copié !")
    }
    
    
    return (
        <div>
            <div className='w-full h-full p-8 bg-white border-2 shadow hover:shadow-lg rounded-md overflow-auto select-none'> 
                {questions
                    .filter(({ answer, comment }) => (answer && answer[0] && answer[0] !== "") || comment)
                    .map(({ parent, name, answer, comment, unit }, index) => 
                        <div key={index} className="py-2 flex"> 
                            <p className="first-letter:capitalize font-semibold w-1/2">{parent ? `${parent} : ${name}`: `${name}`}</p>
                            <ul className="mx-8 list-none">
                                {answer.map(a => <li key={a} className="first-letter:capitalize">{unit ? `${a} ${unitAccorded(a, unit)}` : a}</li>)}
                                {comment ? <li key={comment} className="first-letter:capitalize">{comment}</li> : null}
                            </ul>
                        </div>
                    
                )}
            </div>

            <div className="flex gap-4">
                    <button 
                        className="border bg-white px-4 py-3 rounded-lg font-medium bg-sky-50 text-blue-900 my-4 shadow-lg hover:shadow-xl w-80" 
                        onClick={sendText}
                        style={{ display: answersHaveChangedFromDatabase ? "block" : "none" }}
                        disabled={sendButton === "E-mail envoyé !"}
                    >
                        {sendButton}
                    </button>

                    <button 
                        className="border bg-white px-4 py-3 rounded-lg font-medium bg-sky-50 text-blue-900 my-4 shadow-lg hover:shadow-xl w-80"
                        onClick={handleCopy}
                    >
                        {copyButton}
                    </button>
                </div>
        </div>
    )


}

export default Table