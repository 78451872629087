import { useEffect, useState } from "react"
import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import { changeActiveMenu } from '../app/slices/sidebarSlice'
import { useSendLogoutMutation } from "../app/services/authApiSlice"
import { useResendMutation } from "../app/services/emailConfirmationApiSlice"

import { Navbar } from "../components"
import CheckDoctorIcon from "../icons/CheckDoctorIcon"

import { RxScissors } from 'react-icons/rx'


const AuthRequired = () => {
    const { token, confirmedEmail, email } = useSelector(state => state.auth)
	const { currentThemeMode } = useSelector(state => state.theme)
	const { screenWidth } = useSelector(state => state.screenSize)

    const [inactiveResendButton, setInactiveResendButton] = useState(false)

    const sendLogout = useSendLogoutMutation()[0]
    const resend = useResendMutation()[0]

	const dispatch = useDispatch()
    const location = useLocation()

	useEffect(() => {
		if (screenWidth <= 900) {
			dispatch(changeActiveMenu(false))
		} else {
			dispatch(changeActiveMenu(true))
		}
	}, [screenWidth])

    const handleResend = () => {
        setInactiveResendButton(true)
        resend(email)
    }

    if(!token) return <Navigate to="/login" state={{ from: location }} replace />
    
    if(!confirmedEmail) return <div className="w-screen h-screen">
            <div className="fixed bg-main-bg dark:bg-main-dark-bg navbar w-full flex justify-between items-center" >
                <div className="mx-2"><CheckDoctorIcon size='xl' /></div>
                <li className='flex items-center text-gray-700 text-14 font-bold hover:bg-gray-200 rounded-full p-2 px-4 my-1' onClick={sendLogout} >
                    <div className="text-xl w-6 mr-3 items-center" >
                        <RxScissors/>
                    </div>
                    <span>Déconnexion</span>
                </li>
            </div>
            <div className="w-full h-full flex flex-col place-content-center">
                <p className="text-2xl text-center mx-5 my-6">Confirme ton adresse e-mail avant d'accéder au site.</p>
                <p className="text-base text-center mx-5 my-2">Si tu ne trouves pas l'e-mail de confirmation dans ta boîte mail, vérifie tes spams.</p>
                <div className="first-letter:capitalize words-break text-center mx-5 my-2">
                    Tu n'as pas reçu de mail ? <button disabled={inactiveResendButton} className="ml-1" style={{ color: "rgb(0,150,250)" }} onClick={handleResend}>Clique ici pour le renvoyer</button>.
                </div>
                <p className="first-letter:capitalize words-break text-center mx-5 my-2">
                    Tu rencontres un problème ? Contacte-nous à <a href="mailto:support@check.doctor" style={{ color: "rgb(0,150,250)" }}>support@check.doctor</a>.
                </p>
            </div>
        </div>

    return <div className={currentThemeMode === "Dark" ? "dark" : ""}>
                <div className="flex relative dark:bg-main-dark-bg">
                    <div className='bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2'>
                        <div className="fixed bg-main-bg dark:bg-main-dark-bg navbar w-full" >
                            <Navbar />
                        </div>   
                        <Outlet />
                    </div>
                </div>
            </div>
    
}

export default AuthRequired