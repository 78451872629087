import { GiBowels, GiFootPlaster, GiBackPain, GiFalling, GiVomiting, GiPouringChalice } from "react-icons/gi";
import { BsChatLeftDots } from "react-icons/bs";
import { FaToiletPaperSlash } from "react-icons/fa";
import { FaToilet } from "react-icons/fa";
import { MdSick } from "react-icons/md";

import { bool, radio, checkbox, text, range, date, number } from '../../constants/inputTypes';
import { localisation, timing, travel, position, quantity } from './other';
import { fever, lossOfWeight } from './general';
import { abdominalPain, dysphagia, nausea, vomiting, constipation, diarrhea, jaundice, abdominalRiskFactors } from './abdomen';
import { urines } from './urines';
import { anklePain, backPain } from "./orthopedia";


/*
framework de chaque question : 

{
    name : string
    isReason: bool // décrit si la question est un motif d'admission et doit donc être rendue comme une page à part entière
    pageCode: string // string of 20 characters (numbers and letters min-maj)
    icon : icone
    type: string
    options: array of strings
    units : string // décrit ce qui est demandé ( peut être rempli si terme médical compliqué ou peu utilisé ex : signe de Léri )
    explication: string
    semanticLinkToParents : bool
    formulation : array of string
    children : array of objects { element : array , condition : string }
}

exemple : 

const questions = [
    {
        name : "douleur abdominale",
        isReason : true,
        pageCode : "af5843rze6847gfes35e",
        icon: {icon}
        type : radio,
        options : bool,
        units : "",
        explication : "",
        semanticLinkToParents : false,
        formulation : [
            "douleur abdominale présente", "absence de douleur abdominale"
        ],
        children : [
            { element : douleurAbdo, condition : "oui" }
        ],
    }
]

*/






const questions = {
    general: [
        { 
            name: "perte de poids", 
            frenchNames: ["perte de poids", "amaigrissement"],
            englishNames: ["loss of weight"],
            url: "/2Ed40cuEMrL3fj6wrHzn",
            icon : <BsChatLeftDots />,
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{element: lossOfWeight, condition: "oui"}], 
        },
        { 
            name: "fièvre", 
            frenchNames: ["fièvre", "température"],
            englishNames: ["fever"],
            url: "/2Eg601uEMrL3fj6wrHzn",
            icon : <MdSick />,
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{element: fever, condition: "oui"}], 
        },
    ],

    polytrauma: [
        {
            name: "polytraumatisme",
            frenchNames: ["polytrauma"],
            englishNames: ["polytrauma"],
            url: "/spi6XoHmbPA5JwKy8b6F",
            icon: <GiFalling />,
        },
    ],

    cardiacArrest: [],
    thorax: [],

    abdomen: [
        { 
            name: "douleur abdominale", 
            frenchNames: ["douleur abdominale", "douleur au ventre"],
            englishNames: ["abdominal pain", "belly pain"],
            url: "/Cu31154r17F6imuxunvL",
            icon: <GiBowels />,
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{ element: abdominalPain, condition: "oui" }] 
        },
        { 
            name: "dysphagie",
            frenchNames: ["dysphagie", "difficulté à avaler", "difficultés à avaler", "trouble de la déglutition", "troubles de la déglutition"],
            englishNames: ["dysphagia", "trouble swallowing"],
            url: "/P9a01ssKCtGQF0QCZ3DG",
            icon: <BsChatLeftDots />,
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{ element: dysphagia, condition: "oui" }] 
        },
        { 
            name: "nausée", 
            frenchNames: ["nausée", "nausées", "envie de vomir"],
            englishNames: ["nausea"],
            url: "/M3f37lkfSgUY8CpPxr2G",
            icon: <BsChatLeftDots />,
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{ element: nausea, condition: "oui" }] 
        },
        { 
            name: "vomissement", 
            frenchNames: ["vomissement", "vomissements"],
            englishNames: ["vomiting"],
            url: "/Zx86V1mkhCuziMRWRq53",
            icon: <GiVomiting />,
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{ element: vomiting, condition: "oui" }] 
        },
        { 
            name: "constipation", 
            frenchNames: ["constipation"],
            englishNames: ["constipation"],
            url: "/w9L34J54v8ze6tomOtjX",
            icon: <FaToiletPaperSlash />,
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{ element: constipation, condition: "oui" }] 
        },
        { 
            name: "diarrhée", 
            frenchNames: ["diarrhée"],
            englishNames: ["diarrhea"],
            url: "/5K8za96tsDwYhcfXygeJ",
            icon: <FaToilet />,
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{ element: diarrhea, condition: "oui" }] 
        },
        { 
            name: "ictère",
            frenchNames: ["ictère", "jaunisse"],
            englishNames: ["jaundice"],
            url: "/Nf4896tG2foJJIXwc0HH",
            icon: <BsChatLeftDots />, 
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{ element: jaundice, condition: "oui" }] 
        },
    ],
    urinary: [
        { 
            name: "symptômes urinaires",
            frenchNames: ["symptômes urinaires"],
            englishNames: ["urinary symptoms"],
            url: "/2B3f4cooVbgqcbcaGoj0",
            icon: <GiPouringChalice />, 
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{element: urines, condition: "oui"}] 
        },
    ],
    
    orthopedia: [
        {
            name: "douleur à la cheville",
            frenchNames: ["douleur à la cheville"],
            englishNames: ["ankle pain"],
            url: "/iQfthXywwjWPK2BDN5kb",
            icon: <GiFootPlaster />,
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{element: anklePain, condition: "oui"}] 
        },
        {
            name: "douleur lombaire",
            frenchNames: ["douleur lombaire", "lombalgie"],
            englishNames: ["back pain"],
            curl: "/a4JzN5tuxc52biKJcWcN",
            icon: <GiBackPain />,
            formulation: (state) => state?.answer ? `${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ?(" " + state.comment) : ""}` : null,
            children: [{element: backPain, condition: "oui"}] 
        },
    ],

    dermato: [], //pour la suite cfr livre de référence
}

const symptoms = () => {
    Object.values(questions).forEach(group => group.forEach(question => {

        question.isReason = question.isReason ? question.isReason : true;
        question.semanticLinkToParents = question.semanticLinkToParents ? question.semanticLinkToParents : false;
        question.type = question.type ? question.type : radio;
        question.options = question.options ? question.options : bool;
        question.formulation = question.formulation ? question.formulation : (state => state?.answer ? (`${state.answer === "oui" ? "" : "Pas de "}${state.name}.${state?.comment ? (" " + state.comment) : ""}`) : null);

    }))

    questions.general.forEach(question => {
        question.children.unshift({ element: timing, condition: "oui" });
    })

    questions.orthopedia.forEach(question => {
        question.children.unshift({ element: timing, condition: "oui" });
    })

    questions.abdomen.forEach(question => {
        question.children.unshift({ element: timing, condition: "oui" });
        question.children.push({ element: abdominalRiskFactors, condition: "oui" })
    })

    return questions
}

export default symptoms()
