import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    activeMenu: true,
    searchWord: "",
    showModal: false,
}

export const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        changeActiveMenu: (state, action) => {
            state.activeMenu = action.payload;
        },
        setSearchWord: (state, action) => {
            state.searchWord = action.payload;
        },
        setShowModal: (state, action) => {
            state.showModal = action.payload;
        },
    }
})

export const { changeActiveMenu, setSearchWord, setShowModal } = sidebarSlice.actions;
export default sidebarSlice.reducer;