import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../slices/authSlice'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.NODE_ENV === 'production' ? "https://check-doctor.onrender.com" :"http://localhost:5000", 
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
        const { token } = getState().auth
        if(token){
            headers.set("authorization", token)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    
    if (result?.error?.status === 403) {
        console.log("sending refresh token")
        const refreshResult = await baseQuery("/auth/refresh", api, extraOptions)
        if (refreshResult?.data) {
            const { email } = api.getState().auth
            api.dispatch(setCredentials({ ...refreshResult.data, email }))
            result = await baseQuery(args, api, extraOptions)
        } else {
            if (refreshResult?.error?.status === 403) {
                refreshResult.error.data.message = "Votre session a expiré. "
                api.dispatch(logOut())
            }
            return refreshResult
        }
    }
    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})