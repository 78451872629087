import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'

import { BsCheck } from 'react-icons/bs'

import { setMode, setColor } from '../app/slices/themeSlice'


const ThemeSettings = () => {
    const { currentThemeMode, currentThemeColor } = useSelector(state => state.theme)

    const dispatch = useDispatch();

    const themeColors = [
        {
            name: 'bleu',
            color: '#1A97F5',
        },
        {
            name: 'turquoise',
            color: '#03C9D7',
        },
        {
            name: 'violet',
            color: '#7352FF',
        },
        {
            name: 'rose',
            color: '#FF5C8E',
        },
        {
            name: 'indigo',
            color: '#1E4DB7',
        },
        {
            name: 'orange',
            color: '#FB9678',
        },
    ];

    return (
        <div className=''>
            <div className='m-auto mt-20 sm:mt-32 max-h-xs border rounded-md dark:text-gray-200 bg-white dark:bg-[#484B52] w-400'>
                <div className='flex justify-between items-center p-4 ml-4 h-24'>
                    <p className='font-semibold text-xl'>Apparence et personnalisation</p>
                </div>
                <div className='flex-col border-t-1 border-color p-4 ml-4'>
                    <p className='font-semibold text-lg'>Thème</p>
                    <div className='mt-4'>
                        <input 
                            type='radio'
                            id='light'
                            name='theme'
                            value='Light'
                            className='cursor-pointer'
                            onChange={(e) => dispatch(setMode(e.target.value))}
                            checked={currentThemeMode === "Light"}
                        />
                        <label htmlFor="light" className='ml-2 text-md cursor-pointer'>Clair</label>
                    </div>
                    <div className='mt-4'>
                        <input 
                            type='radio'
                            id='dark'
                            name='theme'
                            value='Dark'
                            className='cursor-pointer'
                            onChange={(e) => dispatch(setMode(e.target.value))}
                            checked={currentThemeMode === "Dark"}
                        />
                        <label htmlFor="dark" className='ml-2 text-md cursor-pointer'>Sombre</label>
                    </div>
                </div>

                <div className='flex-col border-t-1 border-color p-4 ml-4'>
                    <p className='font-semibold text-lg'>Couleur</p>
                    <div className='flex gap-3'>
                        {themeColors.map(item => (
                            <>
                                <div data-tooltip-id={item.name} data-tooltip-content={item.name} className="relative mt-2 cursor-pointer flex gap-5 items-center" >
                                    <button
                                        type='button'
                                        className='w-10 h-10 rounded-full cursor-pointer'
                                        style={{ backgroundColor: item.color }}
                                        onClick={() => dispatch(setColor(item.color))}
                                    >
                                        <BsCheck className={`ml-2 text-2xl text-white ${item.color === currentThemeColor ? "block" : "hidden"}`} />
                                    </button>
                                </div>
                                <Tooltip id={item.name} place="top" effect="solid" />
                            </>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThemeSettings