import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MdDriveFileRenameOutline } from "react-icons/md"

import { useChangeFirstNameMutation, useChangeLastNameMutation, useChangePasswordMutation } from "../app/services/userApiSlice"
import { setCredentials } from "../app/slices/authSlice"

import { USERNAME_REGEX, PASSWORD_REGEX } from "../constants/regex"




const UserAccount = () => {
    const { email, firstName, lastName } = useSelector(state => state.auth)
	const { currentThemeColor } = useSelector(state => state.theme)
	
	const [allowChangeFirstName, setAllowChangeFirstName] = useState(false)
	const [newFirstName, setNewFirstName] = useState(firstName.charAt(0).toUpperCase() + firstName.slice(1))
	const [errorFirstName, setErrorFirstName] = useState("")

	const [allowChangeLastName, setAllowChangeLastName] = useState(false)
	const [newLastName, setNewLastName] = useState(lastName.charAt(0).toUpperCase() + lastName.slice(1))
	const [errorLastName, setErrorLastName] = useState("")

	const [allowChangePassword, setAllowChangePassword] = useState(false)
	const [password, setPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [confirmNewPassword, setConfirmNewPassword] = useState("")
	const [errorPassword, setErrorPassword] = useState("")

	const [successMessage, setSuccessMessage] = useState(false)
	
	const firstNameRef = useRef()
	const lastNameRef = useRef()
	const passwordRef = useRef()
	const dispatch = useDispatch()


	const [changeFirstName, firstNameResult] = useChangeFirstNameMutation()
	const [changeLastName, lastNameResult] = useChangeLastNameMutation()
	const [changePassword, passwordResults] = useChangePasswordMutation()


	useEffect(() => {
		if (allowChangeFirstName) {
			setAllowChangeLastName(false)
			setAllowChangePassword(false)
			firstNameRef.current.focus()
		}
	}, [allowChangeFirstName])

	useEffect(() => {
		if (allowChangeLastName) {
			setAllowChangeFirstName(false)
			setAllowChangePassword(false)
			lastNameRef.current.focus()
		}
	}, [allowChangeLastName])

	useEffect(() => {
		if (allowChangePassword) {
			setAllowChangeFirstName(false)
			setAllowChangeLastName(false)
			passwordRef.current.focus()
		}
	}, [allowChangePassword])

	useEffect(() => {
		setErrorFirstName("")
		setErrorLastName("")
		setErrorPassword("")
	  }, [newFirstName, newLastName, newPassword, confirmNewPassword])

	useEffect(() => {
		if (firstNameResult.isSuccess || lastNameResult.isSuccess || passwordResults.isSuccess) {
			setSuccessMessage(true)
			setAllowChangePassword(false)
			setTimeout(() => {
				setSuccessMessage(false)
			}, 5000)
		}
		if (firstNameResult.isError) {
			setErrorFirstName(firstNameResult.error.data.message)
		}
		if (lastNameResult.isError) {
			setErrorFirstName(lastNameResult.error.data.message)
		}
		if (passwordResults.isError) {
			setErrorPassword(passwordResults.error.data.message)
		}
	}, [firstNameResult, lastNameResult, passwordResults])
	
    
	const cancelChangeFirstName = () => {
		setNewFirstName(firstName.charAt(0).toUpperCase() + firstName.slice(1))
		setAllowChangeFirstName(false)
	}

	const confirmChangeFirstName = () => {
		if (!USERNAME_REGEX.test(newFirstName)){
			setErrorFirstName("Le prénom doit comporter :\n- uniquement des lettres avec ou sans accents ou des espaces. \n- entre 2 et 24 caractères.")
			return
		}
		changeFirstName({ firstName: newFirstName.toLowerCase().trim(), email })
		dispatch(setCredentials({ firstName: newFirstName.toLowerCase().trim() }))
		setAllowChangeFirstName(false)
	}

	const cancelChangeLastName = () => {
		setNewLastName(lastName.charAt(0).toUpperCase() + lastName.slice(1))
		setAllowChangeLastName(false)
	}

	const confirmChangeLastName = () => { 
		if (!USERNAME_REGEX.test(newLastName)){
			setErrorLastName("Le nom doit comporter :\n- uniquement des lettres avec ou sans accents ou des espaces. \n- entre 2 et 24 caractères.")
			return
		}
		changeLastName({ lastName: newLastName.toLowerCase().trim(), email })
		dispatch(setCredentials({ lastName: newLastName.toLowerCase().trim() }))
		setAllowChangeLastName(false)
	}

	const cancelChangePassword = () => {
		setPassword("")
		setNewPassword("")
		setConfirmNewPassword("")
		setAllowChangePassword(false)
	}

	const confirmChangePassword = () => {
		if(!PASSWORD_REGEX.test(newPassword)){
            setErrorPassword("Le mot de passe doit comporter :\n- 8 à 24 caractères.\n- au moins une lettre majuscule.\n- au moins une lettre minuscule.\n- au moins un chiffre.\n- au moins un caractère spécial.\nLes caractères spéciaux autorisés sont : &@#$%/*-+=,;:!?./§")
            return
        }
        if(newPassword !== confirmNewPassword){
            setErrorPassword("Les mots de passe ne correspondent pas.")
            return
        }

		changePassword({ oldPassword: password, newPassword, email })
	}


    return (
		<div>
			<div className="flex flex-col max-w-xs sm:max-w-xl m-auto mt-20 sm:mt-32 max-h-xs border rounded-md">
				<div className="hover:bg-slate-100 rounded-md">
					<div className="py-2 mx-3 border-b">
						<div className="flex flex-row">
							<div className="flex flex-wrap basis-11/12">
								<p className="px-3 pt-3 pb-1 sm:p-3 basis-40 text-sm text-slate-500 self-end">Adresse e-mail</p>
								<p className="px-3 pb-3 pt-1 sm:p-3 basis-60 self-end">{email}</p>
							</div>
						</div>
					</div>
				</div>


				<div className="hover:bg-slate-100 rounded-md">
					
					{ allowChangeFirstName
						? <div className="py-2 mx-3 border-b">

							<div className="flex flex-row">
								<div className="flex flex-wrap basis-11/12">
									<p className="px-3 pt-3 pb-1 sm:p-3 sm:pt-4 basis-40 text-sm text-slate-500 self-start">Prénom</p>										
									<div className="mt-1 flex flex-wrap basis-60 self-end">
										<input ref={firstNameRef} className="m-2 p-2 py-1 basis-60 self-end rounded-md border" type="text" value={newFirstName} onChange={(e) => setNewFirstName(e.target.value)} />
										{ errorFirstName.length > 0 ? <div className="mx-2 self-end errmsg text-rose-600 text-xs" aria-live="assertive">{errorFirstName.split("\n").map(split => <p key={split}>{split}</p>)}</div> : null }
									</div>	
								</div>
							</div>

							<div className="m-3 flex text-sm text-slate-500 justify-end">
								<button className="mx-1 px-3 py-1.5 rounded" onClick={cancelChangeFirstName}>
									Annuler
								</button>
								<button 
									className="mx-1 px-3 py-1.5 rounded text-slate-300 disabled:opacity-50 disabled:text-slate-500" 
									style={{ backgroundColor : firstName === newFirstName.toLowerCase() ? "silver" : currentThemeColor }} 
									onClick={confirmChangeFirstName} 
									disabled={firstName === newFirstName.toLowerCase()} >
										Enregistrer
								</button>
							</div>
							
						</div>

						: <div className="py-2 mx-3 border-b">
							<div className="flex flex-row">
								<div className="flex flex-wrap basis-11/12">									
									<p className="px-3 pt-3 pb-1 sm:p-3 basis-40 text-sm text-slate-500 self-end">Prénom</p>										
									<p className="px-3 pb-3 pt-1 sm:p-3 basis-60 self-end">{firstName.charAt(0).toUpperCase() + firstName.slice(1)}</p>									
								</div>
								<button 
									className="m-1 p-2 basis-1/12 self-center" 
									onClick={() => setAllowChangeFirstName(true)}>
										<MdDriveFileRenameOutline />
								</button>								
							</div>
						</div>
					}
				</div>


				<div className="hover:bg-slate-100 rounded-md">
					{ allowChangeLastName
						? <div className="py-2 mx-3 border-b">

							<div className="flex flex-row">
								<div className="flex flex-wrap basis-11/12">
									<p className="px-3 pt-3 pb-1 sm:p-3 sm:pt-4 basis-40 text-sm text-slate-500 self-start">Nom</p>										
									<div className="mt-1 flex flex-wrap basis-60 self-end">
										<input ref={lastNameRef} className="m-1 p-2 py-1 self-end rounded-md border" type="text" value={newLastName} onChange={(e) => setNewLastName(e.target.value)} />
										{ errorLastName.length > 0 ? <div className="mx-2 self-end errmsg text-rose-600 text-xs" aria-live="assertive">{errorLastName.split("\n").map(split => <p key={split}>{split}</p>)}</div> : null }
									</div>										
								</div>
							</div>

							<div className="m-3 flex text-sm text-slate-500 justify-end">
								<button className="mx-1 px-3 py-1.5 rounded" 
									onClick={cancelChangeLastName}>
										Annuler
								</button>
								<button 
									className="mx-1 px-3 py-1.5 rounded text-slate-300 disabled:opacity-50 disabled:text-slate-500" 
									style={{ backgroundColor : lastName === newLastName.toLowerCase() ? "silver" : currentThemeColor }} 
									onClick={confirmChangeLastName} 
									disabled={lastName === newLastName.toLowerCase()} >
										Enregistrer
								</button>
							</div>
								
						</div>

						: <div className="py-2 mx-3 border-b">
							<div className="flex flex-row">
								<div className="flex flex-wrap basis-11/12">
									<p className="px-3 pt-3 pb-1 sm:p-3 basis-40 text-sm text-slate-500 self-end">Nom</p>
									<p className="px-3 pb-3 pt-1 sm:p-3 basis-60 self-end">{lastName.charAt(0).toUpperCase() + lastName.slice(1)}</p>
								</div>
								<button 
									className="m-1 p-2 basis-1/12 self-center" 
									onClick={() => setAllowChangeLastName(true)}>
										<MdDriveFileRenameOutline />
								</button>							
							</div>
						</div>
					}
				</div>


				<div className="hover:bg-slate-100 rounded-md">
					{ allowChangePassword
						? <div className="py-2 mx-3 border-b">
							<div className="flex flex-row">
								<div className="flex flex-wrap basis-11/12">
									<p className="px-3 pt-3 pb-1 sm:p-3 sm:pt-4 basis-40 text-sm text-slate-500 self-start">Mot de passe</p>
									<div className="mt-1 flex flex-wrap basis-60 self-end">
										<input 
											ref={passwordRef} 
											className="m-1 p-2 py-1 self-end rounded-md border" 
											type="password" 
											placeholder="Ancien mot de passe" 
											value={password} 
											onChange={(e) => setPassword(e.target.value)} />
										<input 
											className="m-1 p-2 py-1 self-end rounded-md border" 
											type="password" 
											placeholder="Nouveau mot de passe" 
											value={newPassword} 
											onChange={(e) => setNewPassword(e.target.value)} />
										<input 
											className="m-1 p-2 py-1 self-end rounded-md border" 
											type="password" 
											placeholder="Confirmation" 
											value={confirmNewPassword} 
											onChange={(e) => setConfirmNewPassword(e.target.value)} />

										{ errorPassword.length > 0 ? <div className="mx-2 self-end errmsg text-rose-600 text-xs" aria-live="assertive">{errorPassword.split("\n").map(split => <p key={split}>{split}</p>)}</div> : null }
									</div>
								</div>
							</div>

							<div className="m-3 flex text-sm text-slate-500 justify-end">
								<button className="mx-1 px-3 py-1.5 rounded" 
									onClick={cancelChangePassword}>
										Annuler
								</button>
								<button 
									className="mx-1 px-3 py-1.5 rounded text-slate-300 disabled:opacity-50 disabled:text-slate-500" 
									style={{ backgroundColor : password.length === 0 || newPassword.length === 0 || confirmNewPassword.length === 0 ? "silver" : currentThemeColor }} 
									onClick={confirmChangePassword} 
									disabled={password.length === 0 || newPassword.length === 0 || confirmNewPassword.length === 0} >
										Enregistrer
								</button>
							</div>
						</div>

						: <div className="py-2 mx-3 border-b">
							<div className="flex flex-row">
								<div className="flex flex-wrap basis-11/12">
									<p className="px-3 pt-3 pb-1 sm:p-3 basis-40 text-sm text-slate-500 self-end">Mot de passe</p>
									<p className="px-3 pb-3 pt-1 sm:p-3 basis-60 self-end">*******</p>
								</div>
								<button 
									className="m-1 p-2 basis-1/12 self-center" 
									onClick={() => setAllowChangePassword(true)}>
										<MdDriveFileRenameOutline />
								</button>	
							</div>
						</div>
					}
					
				</div>

			</div>

			{ successMessage
				? <p className="max-w-xs sm:max-w-xl m-auto mt-3 px-3 py-2 w-full text-sm text-slate-100 text-center bg-emerald-500 rounded">Données correctement mises à jour !</p>
				: null
			}
        </div>
    )
}

export default UserAccount