import { createSlice } from "@reduxjs/toolkit"

// old implementation, to display two components if the screen is horizontal
/*const initialState = window.innerHeight < window.innerWidth 
    ? {
        check: true,
        text: false, 
        log: false,
    }
    : {
        check: false,
        text: false, 
        log: false,
    }

export const navbarSlice = createSlice({
    name: "navbar",
    initialState,
    reducers: {
        resetNavbarState: () => initialState,
        setNavbarState: (state) => {
            Object.assign(state, initialState)
            window.innerHeight < window.innerWidth ? state.text = true : state.check = true
        },
        handleNavbarClick: (state, action) => {
            Object.assign(state, initialState)
            state[action.payload] = true;
        },
    }
})

export const { resetNavbarState, setNavbarState, handleNavbarClick } = navbarSlice.actions
*/

const initialState = {
    componentDisplayed: "questions"
}

export const navbarSlice = createSlice({
    name: "navbar",
    initialState,
    reducers: {
        resetNavbarState: () => initialState,
        handleNavbarClick: (state, action) => {
            state.componentDisplayed = action.payload
        },
    }
})

export const { resetNavbarState, handleNavbarClick } = navbarSlice.actions
export default navbarSlice.reducer