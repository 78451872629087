import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { useGetReasonsMutation, useGetReasonMutation } from "../app/services/questionsApiSlice.js"
import { useGetLogMutation } from "../app/services/submitApiSlice.js"
import { setAnswer, createQuestionsArray, setCurrentReason, setMultipleAnswers } from '../app/slices/recordSlice'
import { resetNavbarState } from "../app/slices/navbarSlice"

import Text from "../components/Text.jsx"
import Questions from "../components/Questions"
import Table from "../components/Table"

import { CgSpinner } from "react-icons/cg"


const Main = () => {
	const { screenOrientation } = useSelector(state => state.screenSize)
	const { componentDisplayed } = useSelector(state => state.navbar)
	const { currentReason, questions, id } = useSelector(state => state.record)

	const [dropdownOpen, setDropdownOpen] = useState(null)
	const [openList, setOpenList] = useState(false)
	const [dispatched, setDispatched] = useState(false)

	const [getReasons, { data: reasons, isSuccess: reasonsSuccess }] = useGetReasonsMutation({ fixedCacheKey: 'shared-reasons' })
	const [getReason, { data, isSuccess }] = useGetReasonMutation()
	const [getLog, { data: logData, isLoading: logLoading, isSuccess: logSuccess }] = useGetLogMutation({ fixedCacheKey: 'shared-log' })	

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(resetNavbarState())
		getReasons()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (currentReason){
			getReason({ reason: currentReason })
		}
		// eslint-disable-next-line
    }, [currentReason])

    useEffect(() => {
        if(isSuccess){
            dispatch(createQuestionsArray(data))
            dispatch(setAnswer({ name: currentReason, answer: ["oui"] }))
        }
    }, [isSuccess, currentReason])

	useEffect(() => {
		if(id) {
			getLog({ id })
		}
	}, [id])

	useEffect(() => {
		if(logSuccess){
			dispatch(setCurrentReason(logData.currentReason))
		}
	}, [logSuccess])

	useEffect(() => {
		if(logSuccess && currentReason && questions.length > 0 && !dispatched){
			dispatch(setMultipleAnswers(logData.questions))
			setDispatched(true)
		}
	}, [logSuccess, currentReason, questions.length])


	useEffect(() => {
		if (dropdownOpen){
			setTimeout(() => {
				setOpenList(true)
			  }, 200)
		}
		else {
			setOpenList(false)
		}
	}, [dropdownOpen])
	
	const categories = reasons?.map(reason => reason.category).filter((value, index, array) => array.indexOf(value) === index)


	if(currentReason === "" && !reasonsSuccess) return null

	if(logLoading) return <div className="h-screen w-screen flex flex-col justify-center items-center">
			<div className="w-full flex justify-center my-5 text-center">Récupération du rapport...</div>
			<div className="w-full flex justify-center my-5"><CgSpinner size={30} className="animate-spin" /></div> 
		</div>
		
	if(currentReason === "" && reasonsSuccess) return <div className="py-20 p-6 w-full h-full flex flex-wrap justify-center content-center relative z-0 bg-slate-50">
		{
		categories.map(category => {
			let classNameStyle = "mx-4 w-80 transition-all duration-700"
			
			if(dropdownOpen && category !== dropdownOpen) classNameStyle = "m-4 w-80 transition-all duration-700 opacity-0"
			if(dropdownOpen === category) classNameStyle += "z-50 absolute flex flex-col justify-center content-center"

			const reasonsFromCategory = reasons.filter((reason) => reason.category === category)
			
			return <div key={category} className={classNameStyle}>

				<button 
					key={category} 
					className="mt-4 mb-2 px-4 py-3 w-full bg-white text-black font-medium border rounded-md shadow hover:shadow-xl z-50"
					onClick={() => dropdownOpen === category ? setDropdownOpen(null) : setDropdownOpen(category)}
				>
					{category.charAt(0).toUpperCase() + category.slice(1)}
				</button>

				{reasonsFromCategory.map(({ reason }, index) => {
						
						const numberOfDropdownColumns = (reasonsFromCategory.length > 11 && screenOrientation === "horizontal") 
							? 3 
							: screenOrientation === "horizontal" && (reason.includes("gauche") || reason.includes("droite") || reason.includes("droit")) 
								? 2 
								: 1

						const translateX = (index % numberOfDropdownColumns - (numberOfDropdownColumns - 1) / 2) * 22

						const translateY = 4+Math.floor(index/numberOfDropdownColumns)*3.5

						return dropdownOpen === category  
							? <button key={index} 
								className={`my-1 px-4 py-2 w-full bg-white text-slate-700 opacity-0 font-normal border rounded-md shadow hover:shadow-xl transition-all duration-1000 absolute z-40`}
								style={openList ? {transform: `translate(${translateX}rem, ${translateY}rem)`, opacity: `100%`} : null}
								onClick={() => dispatch(setCurrentReason(reason))}
							>
								{reason.charAt(0).toUpperCase() + reason.slice(1)}
							</button>
							: null
				})}
					
			</div>
		})}

	</div>

	/*if(currentReason === "" && reasonsSuccess) return <div className="py-20 p-6 w-full h-full flex flex-wrap justify-center content-center relative z-0 bg-slate-50">
		{
		categories.map(category => {
			let classNameStyle = "mx-4 w-80 transition-all duration-700"
			
			if(dropdownOpen && category !== dropdownOpen) classNameStyle = "m-4 w-80 transition-all duration-700 opacity-0"
			if(dropdownOpen === category) classNameStyle += "z-50 absolute flex flex-col justify-center content-center"
			
			return <div key={category} className={classNameStyle}>

				<button 
					key={category} 
					className="mt-4 mb-2 px-4 py-3 w-full bg-white text-black font-medium border rounded-md shadow hover:shadow-xl z-50"
					onClick={() => dropdownOpen === category ? setDropdownOpen(null) : setDropdownOpen(category)}
				>
					{category.charAt(0).toUpperCase() + category.slice(1)}
				</button>

				{reasons
					.filter((reason) => reason.category === category)
					.map(({ reason }, index) => {
						
						const numberOfDropdownColumns = reason.includes("gauche") || reason.includes("droite") || reason.includes("droit") ? 2 : 1

						let translateX = 0 
						if (reason.includes("gauche")) translateX = -11
						else if (reason.includes("droite")) translateX = 11

						const translateY = 4+Math.floor(index/numberOfDropdownColumns)*3.5

						return dropdownOpen === category  
							? <button key={index} 
								className={`my-1 px-4 py-2 w-full bg-white text-slate-700 opacity-0 font-normal border rounded-md shadow hover:shadow-xl transition-all duration-1000 absolute z-40`}
								style={openList ? {transform: `translate(${translateX}rem, ${translateY}rem)`, opacity: `100%`} : null}
								onClick={() => dispatch(setCurrentReason(reason))}
							>
								{reason.charAt(0).toUpperCase() + reason.slice(1)}
							</button>
							: null
				})}
					
			</div>
		})}

		</div>*/
	

	return (
		<div className="h-screen pt-14 pb-1">
			<div className={screenOrientation === "horizontal" ? "flex flex-row max-h-full justify-center": "flex flex-row max-h-full"} >
				
				{componentDisplayed === "questions"
					? <div className={screenOrientation === "horizontal" ? "w-full" : "w-full max-h-full overscroll-contain"}>
						<Questions reasons={reasons} />
					</div>
					: null
				}
				{componentDisplayed === "text" && (
					<div className={screenOrientation === "horizontal" ? "w-1/2 max-h-full pt-3 pb-24 pl-3 pr-4" : "w-full max-h-full overscroll-contain"} >
						<Text />
					</div>
				)}
				{componentDisplayed === "table" && (
					<div className={screenOrientation === "horizontal" ? "w-1/2 max-h-full pt-3 pb-24 pl-3 pr-4" : "w-full max-h-full overscroll-contain"} >
						<Table />
					</div>
				)}

			</div>
		</div>
	)
}

export default Main
