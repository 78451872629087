import { useEffect } from 'react'
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import { GiMedicines, GiPill, GiSyringe, GiMedicalDrip, GiScalpel, GiDna2, GiEyedropper, GiFirstAidKit, GiStickingPlaster } from 'react-icons/gi'
import { RiStethoscopeLine, RiSyringeLine, RiSurgicalMaskLine } from 'react-icons/ri'
import { RxScissors } from 'react-icons/rx'

import { useSendLogoutMutation } from '../app/services/authApiSlice'


const MenuItem = ({ icon, title, customFunc }) => (
    <li 
    className='flex items-center text-gray-700 text-14 font-bold hover:bg-gray-200 rounded-full p-2 px-4 my-1' 
    onClick={customFunc} 
    >
        <div className="text-xl w-6 mr-3 items-center" >
            { icon }
        </div>
        <span>{title}</span>
    </li>
)


const SettingsMenu = ({ focus, setFocus }) => {
	const { firstName } = useSelector(state => state.auth)

	const [sendLogout, { isLoading, isSuccess, isError, error }] = useSendLogoutMutation()

	const navigate = useNavigate()


	useEffect(() => {
		setFocus("")
        if (isSuccess) navigate('/login')
    }, [isSuccess, navigate])

    if (isLoading) return <p>Logging Out...</p>

    if (isError) return <p>Error: {error.data?.message}</p>


    return (
        <div  
        className='flex items-center justify-end relative cursor-pointer' 
        onClick={() => setFocus(focus === "settingsMenu" ? "" : "settingsMenu")} 
        >
            {focus === "settingsMenu" 
            ? <div className='flex items-center rounded-full bg-white p-2 px-6 w-full' >
                <div className="text-xl text-gray-700 rounded-full relative mr-4" ><RiStethoscopeLine size="20px" /></div>
                <p className='text-gray-700 text-14 truncate'>Bonjour <b>{firstName.charAt(0).toUpperCase() + firstName.slice(1)}</b> </p>
            </div>
            : <div 
                className="text-xl text-gray-700 rounded-full relative p-2 hover:bg-light-gray"
                data-tooltip-id="menu" 
				data-tooltip-content="Menu"
            >
                <RiStethoscopeLine size="20px" />
                <Tooltip id="menu" place="top" effect="solid" />
            </div>
            }
            {focus === "settingsMenu" 
            ? (
                <ul className='flex flex-col absolute top-10 right-0 p-2 shadow-lg bg-white w-full' style={{ borderRadius: "1.25rem" }} >
                    <MenuItem icon={<GiDna2/>} title='Compte' customFunc={() => navigate('/user_account')} />
                    <MenuItem icon={<GiEyedropper/>} title='Thème et apparence' customFunc={() => navigate('/theme_settings')} />
                    <MenuItem icon={<GiFirstAidKit/>} title='Aide' customFunc={() => navigate('/support')} />
                    <MenuItem icon={<GiPill/>} title='Donne-nous ton avis !' customFunc={() => navigate('/feedback')} />
                    <MenuItem icon={<GiSyringe/>} title='À propos' customFunc={() => navigate('/about')} />
                    <MenuItem icon={<RxScissors/>} title='Déconnexion' customFunc={sendLogout} />                    
                </ul>
            )
            : null }
        
        </div>
    )
}

export default SettingsMenu