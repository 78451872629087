import { bool, radio, checkbox, text, date, number } from '../../constants/inputTypes';

export const localisation = {
    // ajouter schéma de la région, et permettre plusieurs clicks pour montrer l'intensité de la douleur (exemple, si la douleur est diffuse, mais principalement en FID, on va cliquer sur tous les segments abdominaux mais on pourra également cliquer 2x sur la FID)
    abdomen: {
        segments: [
            { 
                name: "localisation (segments)", 
                isReason: false,
                type: checkbox, 
                semanticLinkToParents : true,
                options: ["hypocondre gauche", "épigastre", "hypocondre droit", "flanc gauche", "région périombilicale", "flanc droit", "fosse iliaque gauche", "hypogastre", "fosse iliaque droite"],
                formulation : (state) => {
                    const { answer, parent } = state;
                    if(answer?.length > 0){
                        let sentence = `${parent.slice(0, parent.indexOf(" : ") + 1)}localisée en `;
                        answer.forEach((a, i) => {
                            sentence += a
                            if(i === answer.length - 1){
                                sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                            } else if (i === answer.length - 2){
                                sentence += " et "
                            } else {
                                sentence += ", "
                            }
                        })

                        return sentence
                    }
                    return ""
                }
            }
        ],
        quadrants: [
            { 
                name: "localisation (quadrants)", 
                isReason: false,
                type: checkbox, 
                semanticLinkToParents : true,
                options: ["supérieur gauche", "supérieur droit", "inférieur gauche", "inférieur droit"],
                formulation : (state) => {
                    const { answer, parent } = state;
                    if(answer?.length > 0){
                        let sentence = `${parent.slice(0, parent.indexOf(" : ") + 1)}localisée en `;
                        answer.forEach((a, i) => {
                            sentence += a
                            if(i === answer.length - 1){
                                sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                            } else if (i === answer.length - 2){
                                sentence += " et "
                            } else {
                                sentence += ", "
                            }
                        })

                        return sentence
                    }
                    return ""
                }
            }
        ],
    },
    ankleAndFoot: [
        { 
            name: "localisation", 
            isReason: false,
            type: checkbox, 
            semanticLinkToParents : true,
            options: ["malléole externe", "malléole interne", "base du 5e métatarsien", "scaphoïde", "calcaneum", "dos du pied", "plante du pied", "orteils"],
            formulation : (state) => {
                const { answer, parent } = state;
                if(answer?.length > 0){
                    let sentence = `${parent.slice(0, parent.indexOf(" : ") + 1)}localisée en `;
                    answer.forEach((a, i) => {
                        sentence += a
                        if(i === answer.length - 1){
                            sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                        } else if (i === answer.length - 2){
                            sentence += " et "
                        } else {
                            sentence += ", "
                        }
                    })

                    return sentence
                }
                return ""
            }
        }
    ], 
    back: [
        { 
            name: "localisation", 
            isReason: false,
            type: checkbox, 
            semanticLinkToParents : true,
            options: ["haute", "basse", "médiane", "paravertébrale gauche", "paravertébrale droite", "latérale gauche", "latérale droite"],
            formulation : (state) => {
                const { answer, parent } = state;
                if(answer?.length > 0){
                    let sentence = `${parent.slice(0, parent.indexOf(" : ") + 1)} `;
                    answer.forEach((a, i) => {
                        sentence += a
                        if(i === answer.length - 1){
                            sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                        } else if (i === answer.length - 2){
                            sentence += " et "
                        } else {
                            sentence += ", "
                        }
                    })

                    return sentence
                }
                return ""
            }
        }
    ],
}

export const timing = [
    {
        name : "début",
        isReason: false,
        type: date,
        semanticLinkToParents : true,
        formulation : (state) => {

            const { precision, unit, time, comment, answer } = state;

            let formattedDate;

            switch(unit){
                case "minute":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 17).replace(",", " à")}` : `le ${new Date(answer).toLocaleString("en-GB").slice(0, 17).replace(",", " aux alentours de ")}`;
                    break;

                case "heure":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 14).replace(",", " à")}h` : `le ${new Date(answer).toLocaleString("en-GB").slice(0, 14).replace(",", " aux alentours de ")}h`;                    
                    break;

                case "jour":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}` : `aux alentours du ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}`;                    
                    break;

                case "semaine":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}` : `aux alentours du ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}`;                    
                    break;

                case "mois":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}` : `en ${new Intl.DateTimeFormat('fr-FR', { month: 'long'}).format(new Date(answer))} ${new Date(answer).getFullYear()}`;                    
                    break;

                case "année":
                    formattedDate = precision === "précis" ? `en ${new Intl.DateTimeFormat('fr-FR', { month: 'long'}).format(new Date(answer))} ${new Date(answer).getFullYear()}` : `en ${new Date(answer).getFullYear()}`;                    
                    break;

                default:
                    formattedDate = new Date(answer).toLocaleString("en-GB").slice(0, 17);
            }

            return answer ? (
                `ayant débuté ${formattedDate}, il y a donc ${precision === "précis" ? "" : "environ "}${time} ${unit}${(time > 1 && unit !== "mois") ? "s" : ""}. ${comment ?(" " + comment) : ""}`
                ) : null
        },
    },
]

export const travel = [
    {
        name : "où",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `destination du voyage : ${state.answer}.` : null
    },
    { 
        name: "revenu de voyage quand", 
        isReason: false,
        semanticLinkToParents : false,
        type: date,
        formulation : (state) => {

            const { precision, unit, time, comment, answer } = state;

            let formattedDate;

            switch(unit){
                case "minute":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 17).replace(",", " à")}` : `le ${new Date(answer).toLocaleString("en-GB").slice(0, 17).replace(",", " aux alentours de ")}`;
                    break;

                case "heure":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 14).replace(",", " à")}h` : `le ${new Date(answer).toLocaleString("en-GB").slice(0, 14).replace(",", " aux alentours de ")}h`;                    
                    break;

                case "jour":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}` : `aux alentours du ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}`;                    
                    break;

                case "semaine":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}` : `aux alentours du ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}`;                    
                    break;

                case "mois":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}` : `en ${new Intl.DateTimeFormat('fr-FR', { month: 'long'}).format(new Date(answer))} ${new Date(answer).getFullYear()}`;                    
                    break;

                case "année":
                    formattedDate = precision === "précis" ? `en ${new Intl.DateTimeFormat('fr-FR', { month: 'long'}).format(new Date(answer))} ${new Date(answer).getFullYear()}` : `en ${new Date(answer).getFullYear()}`;                    
                    break;

                default:
                    formattedDate = new Date(answer).toLocaleString("en-GB").slice(0, 17);
            }

            return answer ? (
                `revenu de voyage ${formattedDate}, il y a donc ${precision === "précis" ? "" : "environ "}${time} ${unit}${(time > 1 && unit !== "mois") ? "s" : ""}${comment ?(". " + comment) : ""}`
                ) : null
        },
    },
    {
        name : "durée du voyage",
        isReason: false,
        type: number,
        unit: ["jour", "semaine", "mois", "année"], 
        min: 0, 
        max: 50,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `voyage ayant duré ${state.answer} ${state?.unit}${state.answer > 1 && state?.unit.slice(-1) !== "s" ? "s": ""}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "durée du trajet",
        isReason: false,
        type: number,
        unit: ["minute", "heure", "jour"], 
        min: 0, 
        max: 100,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `trajet ayant duré ${state.answer} ${state?.unit}${state.answer > 1 && state?.unit.slice(-1) !== "s" ? "s": ""}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "vaccination pertinente",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `vaccination pertinente : ${state.answer}.` : null
    },
    { 
        name: "zones de voyage", 
        isReason: false,
        type: checkbox, 
        semanticLinkToParents : false,
        options: ["rurale", "urbaine", "jungle", "désert", "marécages"], 
        formulation : (state) => {
            const { answer, parent } = state;

            if(answer?.length > 0){
                let sentence = `voyage `;
                const link = {
                    "rurale": "en zone rurale", 
                    "urbaine": "en zone urbaine", 
                    "jungle": "dans la jungle", 
                    "désert": "dans le désert", 
                    "marécages": "dans des marécages",
                }
                answer.forEach((a, i) => {
                    sentence += link[a];
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })

                return sentence
            }
            return ""
        }
    },
    {
        name : "prise de médicaments antipaludéens",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de prise de médicaments antipaludéens" : "prise de médicaments antipaludéens"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]

export const position = [
    { 
        name: `position`, 
        isReason: false,
        type: checkbox, 
        semanticLinkToParents : true,
        options: ["position penchée en avant", "décubitus dorsal", "décubitus ventral", "décubitus latéral gauche", "décubitus latéral droit", "chien de fusil"],
        formulation : (state) => {
            const { answer, parent } = state;

            let word = "";
            if(parent.includes("aggravant")){
                word = "aggravée"
            } else if(parent.includes("soulageant")){
                word = "soulagée"
            }

            if(answer?.length > 0){
                let sentence = `douleur ${word} en `;
                answer.forEach((a, i) => {
                    sentence += a;
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })

                return sentence
            }
            return ""
        }
    }
]


export const drugUsageQuantity = [
    {
        name : "quantité",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => `${state.parent} : consommation de ${state.answer}`
    },
]

export const medicineQuantity = [
    {
        name : "quantité",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => `${state.parent} : prise de ${state.answer}`
    },
]

export const which = [
    {
        name : "lequel (ou lesquels)",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => `${state.parent.slice(state.parent.indexOf(":") + 1)} : ${state.answer}`
    },
]

export const where = [
    {
        name : "où",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => `${state.parent.slice(state.parent.indexOf(":") + 1)} : ${state.answer}`
    },
]

export const twist = [
    {
        name: "torsion",
        isReason: false,
        type: checkbox,
        semanticLinkToParents : true,
        options: ["varus", "valgus", "hyperextension", "hyperflexion", "rotation interne", "rotation externe"],
        formulation : (state) => {
            const { answer, parent } = state;

            if(answer?.length > 0){
                let sentence = `torsion en `;
                answer.forEach((a, i) => {
                    sentence += a;
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })

                return sentence
            }
            return ""
        }
    }
]