import { useEffect, useState, useRef } from "react"
import { Link, useParams } from "react-router-dom"

import { useResetPasswordMutation, useSendResetPasswordEmailMutation } from "../app/services/userApiSlice"

import doctorIcon from '../icons/doctor_icon.png'

const ResetPassword = () => {
    const [email, setEmail] = useState("")
    const [buttonMessage, setButtonMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [sendResetPasswordEmail, emailSent] = useSendResetPasswordEmailMutation()
    const [resetPassword, passwordReset] = useResetPasswordMutation()

    const { token } = useParams()
    const emailRef = useRef()


    useEffect(() => {
        if (!token) emailRef.current.focus()
    }, [])

    useEffect(() => {
        setErrorMessage("")
    }, [email])

    useEffect(() => {
        if (token) {
            if (passwordReset.isUninitialized){
                setButtonMessage("Réinitialiser le mot de passe")
            } else if (passwordReset.isLoading){
                setButtonMessage("Réinitialisation du mot de passe...")
            } else if (passwordReset.isError){
                passwordReset.error.data.message 
                    ? setButtonMessage(passwordReset.error.data.message)
                    : setButtonMessage("Erreur avec le serveur")
            } else if (passwordReset.isSuccess){
                setButtonMessage("Mot de passe réinitialisé")
            }
        } else {
            if (emailSent.isUninitialized){
                setButtonMessage("Envoyer le lien")
            } else if (emailSent.isLoading){
                setButtonMessage("En cours d'envoi...")
            } else if (emailSent.isError){
                emailSent.error.data.message 
                    ? setButtonMessage(emailSent.error.data.message)
                    : setButtonMessage("Erreur avec le serveur")
            } else if (emailSent.isSuccess){
                setButtonMessage("Lien envoyé")
            }
        }
    }, [passwordReset, emailSent])
    
    
    const handleClick = () => {
        resetPassword({ token })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!email) return setErrorMessage("Veuillez entrer une adresse e-mail valide.")
        sendResetPasswordEmail({ email })
    }
 

    if (token) return (
        <div className="flex flex-col justify-center items-center h-screen">      
            <div className="items-baseline gap-1 flex justify-center text-5xl font-extrabold tracking-tight dark:text-white text-slate-900 mb-6">
                <span> Check.</span>
                <img className="object-cover h-12" src={doctorIcon} alt="doctor icon" />
            </div>
            <p className="flex justify-center text-center pb-1 text-sm w-9/12 max-w-sm">Cliquez sur le bouton ci-dessous pour réinitaliser votre mot de passe</p>        
            <button className="border p-2 px-3 my-2 rounded bg-green-500 text-white w-9/12 max-w-sm" onClick={handleClick} disabled={!passwordReset.isUninitialized} >{buttonMessage}</button>
            <Link to="/login" className="flex justify-center text-center my-6 mb-12" style={{ color: "rgb(0,150,250)" }}>
                Revenir à l'écran de connexion
            </Link>
        </div>
    )
    
    return (
        <div className="flex flex-col justify-center items-center h-screen">
                <div className="w-9/12 max-w-sm">
                    <form onSubmit={handleSubmit} >
                        <div className="flex flex-col">
                            <div className="items-baseline gap-1 flex justify-center text-5xl font-extrabold tracking-tight dark:text-white text-slate-900 mb-6">
                                <span> Check.</span>
                                <img className="object-cover h-12" src={doctorIcon} alt="doctor icon" />
                            </div>
                            <h2 className="flex justify-center text-center pb-1 text-xl font-extrabold">Réinitialisez votre mot de passe</h2>
                            <p className="flex justify-center text-center pb-1 text-sm mb-6">Veuillez entrer votre adresse e-mail et nous vous enverrons le lien pour réinitialiser votre mot de passe</p>
                            <input 
                                className="border p-2 my-1 rounded" 
                                type="email" 
                                id="email" 
                                name="email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                                placeholder="Adresse e-mail" 
                                ref={emailRef}
                                required
                            />
                            <button className="border p-1 my-2 rounded bg-green-500 text-white" type="submit" disabled={!emailSent.isUninitialized} >{buttonMessage}</button>
                            <div className={errorMessage ? "errmsg px-1 text-rose-600 text-xs" : "offscreen"} aria-live="assertive">{errorMessage.split("\n").map(split => <p key={split}>{split}</p>)}</div>
                            <Link to="/login" className="flex justify-center text-center my-6 mb-24" style={{ color: "rgb(0,150,250)" }}>
                                Revenir à l'écran de connexion
                            </Link>
                        </div>
                        
                    </form>
                </div>
        </div> 
           
    )
}

export default ResetPassword