import { apiSlice } from "./apiSlice"

export const textApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReport: builder.mutation({
            query: ({ state, currentReason }) => ({
                url: `/text`,
                method: "POST",
                body: { questions: state, reason: currentReason }
            })
        }),
    })
})

export const { useGetReportMutation } = textApiSlice
