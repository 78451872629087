import "./App.css"
import 'react-tooltip/dist/react-tooltip.css'

import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef } from "react"

import { handleResize } from './app/slices/screenSizeSlice'
import { useRefreshMutation } from "./app/services/authApiSlice"

import Main from "./pages/Main"
import GetText from "./pages/GetText"
import Login from "./pages/Login"
import Register from "./pages/Register"
import ConfirmEmail from "./pages/ConfirmEmail"
import HomePageRedirection from "./components/HomePageRedirection"
import AuthRequired from "./components/AuthRequired"
import AuthForbidden from "./components/AuthForbidden"
import UserAccount from "./pages/UserAccount"
import ResetPassword from "./pages/ResetPassword"
import ThemeSettings from "./pages/ThemeSettings"
import Support from "./pages/Support"
import Feedback from "./pages/Feedback"
import About from "./pages/About"
import Terms from "./pages/Terms"


const App = () => {
	const { token, persist, doneFetching } = useSelector(state => state.auth)
    const effectRan = useRef(false)

    const [refresh, { isLoading }] = useRefreshMutation()

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(handleResize())
		window.addEventListener("resize", () => dispatch(handleResize()))

		if(effectRan.current === true || process.env.NODE_ENV !== "development"){
			const verifyRefreshToken = async () => {
				try {
					await refresh()
				} catch (err) {
					console.error(err)
				}
			}
	
			if(!token && persist) verifyRefreshToken()
		}

		return () => {
			effectRan.current = true
			window.removeEventListener("resize", () => dispatch(handleResize()))
		}

		// eslint-disable-next-line
	}, [])

	if(!isLoading && doneFetching) return (
		<Router>
			<Routes>
				<Route path='/' element={<HomePageRedirection />}/>

				<Route path='/confirmation/:token' element={<ConfirmEmail />} />
				<Route path='/terms' element={<Terms />} />
				<Route path='/get_text/:uuid' element={<GetText/>} />

				<Route element={<AuthForbidden />} >
					<Route path='/login' element={<Login/>} />
					<Route path='/register' element={<Register/>} />
					<Route path='/reset_password/:token?' element={<ResetPassword/>} />
				</Route>
			
				<Route element={<AuthRequired />} >
					<Route path='/main' element={<Main/>} />
					<Route path='/user_account' element={<UserAccount/>} />
					<Route path='/theme_settings' element={<ThemeSettings/>} />
					<Route path='/support' element={<Support/>} />
					<Route path='/feedback' element={<Feedback/>} />
					<Route path='/about' element={<About/>} />
				</Route>
			</Routes>
		</Router>
	);
}

export default App;
