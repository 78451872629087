import { bool, radio, checkbox, text, range, date, number } from '../../constants/inputTypes';
import { localisation, timing, travel, position, quantity } from './other';

export const urines = [
    {
        name : "pollakiurie",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de pollakiurie" : "présence de pollakiurie"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "polyurie",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de polyurie" : "présence de polyurie"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "dysurie",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de dysurie" : "présence de dysurie"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "incontinence",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas d'incontinence urinaire" : "présence d'incontinence urinaire"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "oligo-anurie",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas d'oligo-anurie" : "oligo-anurie"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "hématurie",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas d'hématurie" : "présence d'hématurie"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]