import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setAnswer, changeQuestionsSequence } from '../app/slices/recordSlice'
import { useGetChildrenMutation } from '../app/services/questionsApiSlice'
import { useGetReasonsMutation } from "../app/services/questionsApiSlice.js"

const InputRadioCheckbox = ({ question }) => {
    const { name, type, options, parent, answer, hasChildren } = question
    
    const { currentThemeColor } = useSelector(state => state.theme)
    const { currentReason } = useSelector(state => state.record)

    const [getChildren, { data, isSuccess }] = useGetChildrenMutation()
    const { data: reasons, isSuccess: reasonsSuccess } = useGetReasonsMutation({ fixedCacheKey: 'shared-reasons' })[1]

    const currentCategory = reasons?.find(({ reason }) => reason === currentReason).category
    
    const dispatch = useDispatch()

    useEffect(() => {
        if (name === "sexe" && currentCategory === "appareil génital masculin"){
            dispatch(setAnswer({ name, parent, answer: ["masculin"] }))
        } 
        else if (name === "sexe" && currentCategory === "appareil génital féminin"){
            dispatch(setAnswer({ name, parent, answer: ["féminin"] }))   
        } 
    }, [])
    


    useEffect(() => {
        if(hasChildren && answer && answer.length > 0){
            getChildren({ name, parent, answer })
        }
    }, [answer])


    useEffect(() => {
        if(isSuccess){
            dispatch(changeQuestionsSequence({ question, data }))
        }
    }, [isSuccess])


    const handleInputChange = (e) => {        
        dispatch(setAnswer({ name, parent, answer: [e.target.value]}))
    }


    const handleCheckboxChange = (option, checked) => {
        if (checked) {
            dispatch(setAnswer({ name, parent, answer: answer ? [...answer, option] : [option] }))
        } else {
            dispatch(setAnswer({ name, parent, answer: answer.filter((item) => item !== option) }))
        }
    }

    const isChecked = (option) => {
        const checked = answer ? (answer.findIndex(a => a === option) > -1) : false
        return checked
    }

    /*const other = (index) => <label key={index} className="flex items-center space-x-2">
            <input
                type="checkbox"
                value="autre"
                name={name}
                className="form-checkbox ml-1 mr-4"
                style={{ accentColor: currentThemeColor }}
                onChange={(e) => handleCheckboxChange("autre", e.target.checked)}
                checked={isChecked("autre")}
            />

            <span className='hover:font-medium inline-block first-letter:capitalize'>
                Autre : 
                <input type="text" ></input>
            </span>
        </label>*/
    
    switch (type) {
        case 'checkbox':
            return (
                <div className="my-3">
                    {options.map((option, index) => (
                        <label key={index} className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                value={option}
                                name={name}
                                className="form-checkbox ml-1 mr-4"
                                style={{ accentColor: currentThemeColor }}
                                onChange={(e) => handleCheckboxChange(option, e.target.checked)}
                                checked={isChecked(option)}
                            />

                            <span className='hover:font-medium inline-block first-letter:capitalize'>{option}</span>
                        </label>
                    ))}
                </div>
            )

        case 'radio':
        case 'bool':
            return (
                <div className="my-3">
                    {options.map((option, index) => (
                        <label key={index} className="flex items-center space-x-2">
                            <input
                                type="radio"
                                value={option}
                                name={name}
                                className="form-radio ml-1 mr-4"
                                style={{accentColor: currentThemeColor}}
                                onChange={handleInputChange}
                                checked={isChecked(option)}
                                disabled={name === "sexe" && currentCategory?.includes("appareil génital")}
                            />
                            <span className='hover:font-medium inline-block first-letter:capitalize'>{option}</span>
                        </label>
                    ))}
                </div>
            )

    default:
        return null;
    }
    
}

export default InputRadioCheckbox