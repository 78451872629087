import { apiSlice } from "./apiSlice"

export const applicationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        join: builder.mutation({
            query: data => ({
                url: "/applications",
                method: "POST",
                body: data
            })
        }),
    })
})

export const { useJoinMutation } = applicationsApiSlice