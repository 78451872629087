import { createSlice } from "@reduxjs/toolkit";

const initializePersist = () => {
    if(typeof JSON.parse(localStorage.getItem("persist")) === "undefined"){
        localStorage.setItem("persist", JSON.stringify(true))
        return true
    }
    return JSON.parse(localStorage.getItem("persist"))
}

const initialState = {
    firstName: null,
    lastName: null,
    email : null, 
    token: null,
    confirmedEmail: false,
    persist: true,
    doneFetching: false,
}

const authSlice = createSlice({
    name: "auth", 
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { firstName, lastName, email, signedToken: token, confirmedEmail } = action.payload
            state.firstName = firstName ? firstName : state.firstName
            state.lastName = lastName ? lastName : state.lastName
            state.email = email ? email : state.email
            state.token = token ? token : state.token
            state.confirmedEmail = confirmedEmail ? confirmedEmail : state.confirmedEmail
        },
        logOut: () => initialState,
        setPersist: (state, action) => {
            state.persist = action.payload
            localStorage.setItem("persist", JSON.stringify(action.payload))
        },
        setDoneFetching: (state) => {
            state.doneFetching = true
        }
    }
})

export const { setCredentials, logOut, setPersist, setDoneFetching } = authSlice.actions

export default authSlice.reducer