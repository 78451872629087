import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setAnswer, setUnit } from '../app/slices/recordSlice'

import unitAccorded from "../functions/unitAccorded"

const InputNumber = ({ question }) => {
    const { name, options, parent, answer, unit } = question
    
    const { currentThemeColor } = useSelector(state => state.theme)
    const [inputFocus, setInputFocus] = useState(false)
    const userResponse = answer ? answer[0] : ""

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setUnit({ name, parent, unit: options[0] }))
    }, [])

    const handleInputChange = (e) => {
        dispatch(setAnswer({ name, parent, answer: [e.target.value] }))
    }

    const handleUnitChange = (e) => {
        dispatch(setUnit({ name, parent, unit: e.target.value }))
    }
    
    return (
        <div className="my-3 hover:font-medium">
            <input 
                className="px-1 ml-1 mr-4 w-14 outline-none" 
                style={inputFocus ? {borderBottom: `1px solid ${currentThemeColor}`} : {borderBottom: `1px solid rgb(209 213 219)`}}
                onFocus={()=> setInputFocus(true)}
                onBlur={() => setInputFocus(false)}
                type='number' 
                name={name} 
                value={userResponse}  
                onChange={handleInputChange}
            />

            {options.length > 1 
            ? <select className="inline-block outline-none" onChange={handleUnitChange} value={unit} > 
                {options.map(u => <option value={u} key={u} >{unitAccorded(userResponse, u)}</option>)}
            </select>
            : <label className="inline-block" htmlFor={name}>{unitAccorded(userResponse, options[0])}</label>
            }
        </div>
    )

        
}

export default InputNumber