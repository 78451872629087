import { apiSlice } from "./apiSlice";
import { logOut, setCredentials, setDoneFetching } from "../slices/authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: "/auth/login",
                method: "POST",
                body: { ...credentials }
            })
        }),
        sendLogout: builder.mutation({
            query: () => ({
                url: "/auth/logout",
                method: "POST",
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }){
                try {
                    await queryFulfilled
                    dispatch(logOut())
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                    }, 1000)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        refresh: builder.mutation({
            query: () => ({
                url: "/auth/refresh",
                method: "GET",
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }){
                try {
                    const { data } = await queryFulfilled
                    dispatch(setCredentials({ ...data }))
                    dispatch(setDoneFetching())
                } catch (err) {
                    dispatch(setDoneFetching())
                }
            }

        }),
        register: builder.mutation({
            query: userInfo => ({
                url: "/auth/register",
                method: "POST",
                body: userInfo,
            }),
        })
    })
})

export const { useLoginMutation, useSendLogoutMutation, useRefreshMutation, useRegisterMutation } = authApiSlice