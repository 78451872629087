import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    screenWidth: null,
    screenOrientation: "horizontal",
};

export const screenSizeSlice = createSlice({
    name: "screenSize",
    initialState,
    reducers: {
        handleResize: (state) => {
            state.screenWidth = window.innerWidth;
            state.screenOrientation = window.innerHeight < window.innerWidth ? "horizontal" : "vertical";
        },
    }
})

export const { handleResize } = screenSizeSlice.actions;
export default screenSizeSlice.reducer;