import { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import { setCredentials } from "../app/slices/authSlice"
import { useLoginMutation } from "../app/services/authApiSlice"

import doctorIcon from '../icons/doctor_icon.png'
import CheckDoctorIcon from '../icons/CheckDoctorIcon'


//pour bouton persist cfr https://youtu.be/9YnZHQsWmJs?t=1338

const Login = () => {
    const { screenOrientation, screenWidth } = useSelector(state => state.screenSize)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errMessage, setErrMessage] = useState("")


    const [login, { isLoading }] = useLoginMutation()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const emailRef = useRef()
    const errRef = useRef()

    
    useEffect(() => {
        emailRef.current.focus()
    }, [])
    
    useEffect(() => {
        setErrMessage("")
    }, [email, password])


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const userData = await login({ email: email.toLowerCase().trim(), password }).unwrap()
            dispatch(setCredentials({ ...userData, email: email.toLowerCase().trim() }))
            setEmail("")
            setPassword("")
            navigate("/main")

        } catch (err) {
            if (!err?.status) {
                setErrMessage("Pas de réponse du serveur")
            } else if (err.status === 400) {
                setErrMessage("Merci de remplir l'email et le mot de passe")
            } else if (err.status === 401) {
                setErrMessage(err.data.message)
            } else {
                setErrMessage("Échec d'authentification")
            }
            errRef.current.focus()
        }
    }


    const formJSX = <div>
        
        <form onSubmit={handleSubmit} >
            <div className="flex flex-col">
                <input 
                    className="border p-2 my-1 rounded" 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder="Adresse e-mail" 
                    ref={emailRef}
                    required
                />
                <input 
                    className="border p-2 my-1 rounded" 
                    type="password" 
                    id="password" 
                    name="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    placeholder="Mot de passe" 
                    required
                />
                <p ref={errRef} className={errMessage ? "errmsg px-1 text-rose-600" : "offscreen"} aria-live="assertive">{errMessage}</p>
                <button className="border p-1 my-2 rounded bg-green-500 text-white" type="submit" >Se connecter</button>
            </div>
        </form>       
                
        <p className="first-letter:capitalize words-break text-center my-1" >
            <Link to="/reset_password" className="ml-1" style={{color: "rgb(0,150,250)"}}>Mot de passe oublié ?</Link>
        </p>
        <p className="first-letter:capitalize words-break text-center my-1" >
            Pas encore de compte ? Veuillez vous<Link to="/register" className="ml-1" style={{ color: "rgb(0,150,250)" }}>inscrire</Link>
        </p>
                           
    </div>           
        

    if (screenWidth < 450) return (
        <div className="flex flex-col items-center h-screen">

            <div className="items-baseline gap-1 flex tracking-tight dark:text-white text-slate-900 mx-10 mt-10">
                <CheckDoctorIcon size="6xl" />
            </div>
            
            <div className="flex flex-col mx-8 my-5 content-center max-w-sm">
                <div className="my-2">
                    {formJSX}
                </div> 
            </div> 

        </div>
    )

    else if (screenOrientation === "vertical" || screenWidth < 900) return (
        <div className="flex flex-col items-center h-screen">

            <div className="items-baseline gap-1 flex tracking-tight dark:text-white text-slate-900 mx-10 mt-10">
                <CheckDoctorIcon size="7xl" />
            </div>
            
            <div className="flex flex-col mx-8 my-5 content-center" style={{ width: 340 }}>
                <div className="my-2">
                    {formJSX}
                </div> 
            </div> 

        </div>
    )
    
    else return (
        <div className="flex flex-row items-center justify-center h-screen">
            
            <div className="items-baseline justify-center gap-1 flex tracking-tight dark:text-white text-slate-900 w-1/2 mx-auto">
                <CheckDoctorIcon size="8xl" />
            </div>

            <div className="flex flex-col my-5 content-center w-1/2">
                <div className="my-2 w-8/12 max-w-lg ms-10">
                    {formJSX}
                </div>
            </div>   
            
        </div>
    )
}

export default Login