import { apiSlice } from "./apiSlice"

export const questionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReason: builder.mutation({
            query: ({ reason }) => ({
                url: `/questions/reason/${reason}`,
                method: "GET",
            })
        }),
        getQuestions: builder.mutation({
            query: ({ url }) => ({
                url: `/questions/${url}`,
                method: "GET",
            })
        }),
        getChildren: builder.mutation({
            query: ({ parent, name, answer }) => ({
                url: `/questions/question/q=${name}&a=${answer?.join(',')}&p=${parent}`,
                method: "GET",
            })
        }),
        getReasons: builder.mutation({
            query: () => ({
                url: `/questions/reasons`,
                method: "GET",
            })
        }),
    })
})

export const { useGetReasonMutation, useGetQuestionsMutation, useGetChildrenMutation, useGetReasonsMutation } = questionsApiSlice