import { bool, radio, checkbox, text, range, date, number } from '../../constants/inputTypes';
import { localisation, timing, travel, position, quantity, which, drugUsageQuantity } from './other';
import symptoms from './symptoms';

export const lossOfWeight = [
    {
        name : "appétit",
        isReason: false,
        type: radio,
        options: ["normal", "augmenté", "diminué"],
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `appétit ${state.answer}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "alimentation cause des douleurs",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de douleur ressentie lors de l'alimentation" : "l'alimentation cause des douleurs"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "désir de manger",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de désir de manger" : "désir de manger"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "dysgueusie",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de dysgueusie" : "présence de dysgueusie"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]

export const fever = [
    {
        name : "combien",
        isReason: false,
        type: number,
        unit: "°C", 
        min: 25, 
        max: 44,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.parent} mesurée à ${state.answer}${state.unit}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "contage",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de contage" : "contage"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "voyage à l'étranger",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: travel, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de voyage récent à l'étranger" : "voyage récent à l'étranger"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "prise d'aliments suspects",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} sans d'aliment causal suspect` : `${state.parent} potentiellement provoquée par des aliments`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },  
    {
        name : "piqûre/morsure d'insectes/arthropodes",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `pas de piqûre ou morsure d'insecte ou d'arthropode` : `piqûre/morsure d'insecte/arthropode`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },  
    {
        name : "contact avec animaux",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `pas de contact avec des animaux` : `contact avec des animaux potentiellement vecteur`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },  
    {
        name : "rapports sexuels non protégés",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `pas de rapport sexuel non protégé` : `rapports sexuels non protégés`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },  
    {
        name : "médication potentiellement causale",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} n'ayant pas de médication potentiellement causale` : `${state.parent} ayant pu être causé(e) par une médication`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },   
    {
        name : "immunosuppresseurs",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `pas de prise d'immunosuppresseurs` : `prise d'immunosuppresseurs`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },   
    {
        name : "drogues intraveineuses",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: drugUsageQuantity, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas d'usage de drogues intraveineuses" : "usage de drogues intraveineuses"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]