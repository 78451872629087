import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"

import { addId } from "../app/slices/recordSlice"


const GetText = () => {    
    const { uuid } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addId(uuid))
        navigate('/main')
    }, [])

   
}

export default GetText