import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"


const HomePageRedirection = () => {
    const isAuthenticated = useSelector(state => state.auth.token)
    return (
        isAuthenticated
			? <Navigate to="/main" replace />
			: <Navigate to="/login" replace />
    )
}

export default HomePageRedirection