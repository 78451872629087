import { apiSlice } from "./apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        confirm: builder.mutation({
            query: token => ({
                url: "/confirmation/confirm",
                method: "POST",
                body: { token }
            })
        }),
        resend: builder.mutation({
            query: email => ({
                url: "/confirmation/resend",
                method: "POST",
                body: { email }
            })
        })
    })
})

export const { useConfirmMutation, useResendMutation } = authApiSlice