import { Link } from 'react-router-dom'

import doctorIcon from './doctor_icon.png'


const CheckDoctorIcon = ({ size }) => {
	const textSize = {
		xs:	{
			fontSize: "0.75rem", 
		},
		sm:	{
			fontSize: "0.875rem", 
		},
		base: {
			fontSize: "0.1rem", 
		},
		lg:	{
			fontSize: "1.125rem", 
		},
		xl:	{
			fontSize: "1.25rem", 
		},
		"2xl":	{
			fontSize: "1.5rem",
		},
		"3xl":	{
			fontSize: "1.875rem",
		},
		"4xl":	{
			fontSize: "2.25rem", 
		},
		"5xl":	{
			fontSize: "3rem",
		},
		"6xl":	{
			fontSize: "3.75rem", 
		},
		"7xl":	{
			fontSize: "4.5rem", 
		},
		"8xl":	{
			fontSize: "6rem", 
		},
		"9xl":	{
			fontSize: "8rem",
		},
	}	

	return (
		<Link to="/" className="items-baseline gap-1 flex font-extrabold tracking-tight dark:text-white text-slate-900" >
			<p style={{ fontSize: textSize[size].fontSize, lineHeight: 1 }} >Check.</p>
			<img className="object-cover me-1" style={{height: `${textSize[size].fontSize.slice(0, -3)*1.125}rem`}} src={doctorIcon} alt="doctor icon" />
		</Link>
	)
}

export default CheckDoctorIcon
