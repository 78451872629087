import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import { useSendFbMutation } from '../app/services/feedbackApiSlice'

const Feedback = () => {
    const { currentThemeColor } = useSelector(state => state.theme)
    const { email } = useSelector(state => state.auth)

    const [open, setOpen] = useState({
        general: true,
        positive: true,
        improvements: true,
    })
    const [feedback, setFeedback] = useState({
        general: '',
        positive: '',
        improvements: '',
    })

    const [sendFb, { isError, isSuccess }] = useSendFbMutation()
    
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess){
            setTimeout(() => {
                navigate('/')
            }, 7000)
        }
    }, [isSuccess])

    const handleSubmit = (e) => {
        e.preventDefault()
        Object.entries(open).forEach(([key, value]) => (value && feedback[key].length > 0) && sendFb({ email, type: key, feedback: feedback[key] }))
    }

    const fbLabels = {
        general: "Ton impression générale",
        positive: "Raconte-nous ce que tu as aimé",
        improvements: "Suggère-nous des améliorations",
    }

    const fbQuestions = {
        general: `Quelle a été ton impression générale du site ?\nTes attentes ont été satisfaites, non satisfaites, dépassées ?`,
        positive: `Qu'as-tu trouvé le plus utile ?\nQuelle est selon toi la fonctionnalité la plus importante du site ?\nY a-t-il quelque chose qui t'a particulièrement plu ?`,
        improvements: `Quelles fonctionnalités ou contenu aimerais-tu voir apparaître prochainement ?\nComment améliorerais-tu le site en général ?\nComment rendrais-tu le site plus convivial ?`
    }

    return (
    <div className="bg-gray-700 min-h-screen flex items-center justify-center">
        <div className="bg-white rounded-lg shadow-lg p-8 w-1/2 mt-5">
            {!isSuccess 
            ?<>
                <h1 className="text-5xl font-bold text-gray-900 mb-10 text-center">
                    Donne-nous ton  <span style={{ color: currentThemeColor }} >avis</span>
                </h1>

                <form onSubmit={handleSubmit} className='flex flex-col'>
                    {Object.entries(fbQuestions).map(([key, placeholder]) => 
                    <div className="flex flex-col mb-8" key={key} >
                            <div>
                                <input
                                    type="checkbox" 
                                    className="ml-4 mr-4" 
                                    checked={open[key]}
                                    onChange={() => setOpen({ ...open, [key]: !open[key] })} 
                                    id={key}
                                    style={{accentColor : open[key] ? currentThemeColor : "white"}}
                                />
                                <label 
                                    className="inline-block first-letter:capitalize" 
                                    htmlFor={key}
                                >
                                    {fbLabels[key]}
                                </label>
                            </div>

                            {open[key] 
                                ? <textarea
                                    id={key}
                                    className="mt-2 p-4 w-full h-40 border border-gray-300 rounded"
                                    value={feedback[key]}
                                    onChange={(e) => setFeedback({ ...feedback, [key]: e.target.value })}
                                    placeholder={placeholder}
                                /> 
                                : null
                            }
                        </div> 
                    )}

                    <button
                        className="py-3 px-6 text-white font-bold rounded hover:bg-pink-600 transition duration-300 opacity-75 disabled:opacity-50 hover:opacity-100 w-1/2 self-center"
                        style={{ backgroundColor: currentThemeColor }}
                        type="submit"
                        disabled={feedback.general === '' && feedback.positive === '' && feedback.improvements === '' }
                    >
                        Envoyer
                    </button>
                    <p className='text-center self-center mt-3'>Ou<Link to="/support" className="ml-1" style={{ color: "rgb(0,150,250)" }}>signale-nous un bug.</Link></p>
                    {isError
                        ? <p className='text-center self-center mt-3 text-red-600'>Une erreur est survenue lors de l'envoi de ton avis. Réessaie plus tard.</p>
                        : null
                    }
                </form>
            </>
           : <div className="text-center">
              <h1 className="text-5xl font-bold text-gray-900 mb-8">
                Merci pour ton avis ! 
              </h1>
              <p className="text-lg text-gray-900">
                Il compte beaucoup pour nous.<br/>
                Tu vas être redirigé vers la page d'accueil.<br/>
                N'hésite pas à remplir à nouveau ce formulaire si tu veux nous faire part d'autres commentaires.
              </p>
            </div>
          }
        </div>
    </div>
    )
}
        




/*Aide-nous à nous améliorer == lien vers page feedback <br/>
        
As-tu rencontré une erreur ou des difficultés pour naviguer sur le site ou trouver des informations spécifiques ? 
Autres questions

Page feedback : 
Quelle a été ton impression générale du site
    Comment as-tu entendu parler de Check.doctor ?
    Tes attentes ont été satisfaites, non satisfaites ou dépassées ? 
Raconte-nous ce que tu as aimé
    Qu'est-ce que tu as trouvé le plus utile
    Quelle est selon toi la fonctionnalité la plus importante du site
    Y a-t-il quelque chose qui t'a particulièrement plu ?
Suggère-nous des améliorations
    Quelles fonctionnalités ou contenu aimerais-tu voir apparaître prochainement ? 
    Comment tu améliorerais la convivialité du site ?*/

export default Feedback