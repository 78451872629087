import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    currentReason: "",
    id: '',
    questions: []
}

const getIndex = (state, parent, name) => {
    return parent 
        ? state.questions.findIndex(r => (r.name === name) && (r.parent == parent))
        : state.questions.findIndex(r => r.name === name)
}

export const recordSlice = createSlice({
    name: "record",
    initialState,
    reducers: {
        resetState: () => initialState,

        setCurrentReason: (state, action) => {
            return { questions: initialState.questions, id: state.id, currentReason: action.payload }
        },

        eraseRecordElement: (state, action) => {
            const { name, parent } = action.payload
            const recordIndex = getIndex(state, parent, name)
            state.questions[recordIndex] = { ...state.questions[recordIndex], answer: [], comment: '' }
        },

        createQuestionsArray: (state, action) => {
            state.questions = action.payload
                .filter(({ excluded }) => !excluded)
                .map(({ excluded, ...rest }) => rest)
        },

        changeQuestionsSequence: (state, action) => {
            const { question, data } = action.payload
            const { name, parent } = question
            
            
            const includedQuestions = data
                .slice(1)   // deletes first item (which is the question)
                //.filter(({ excluded }) => !excluded)    // excludes the excluded items 
                //.map(({ excluded, ...rest }) => rest)   // only keeps the relevant key-value pairs (excluded key should not be stored in state)
                .filter(({ name, parent }) => state.questions.findIndex(e => e.name === name && e.parent == parent) === -1)     // excludes the questions which are already stored in the state
            
            const index = getIndex(state, parent, name)

            state.questions = [...state.questions.slice(0, index + 1), ...includedQuestions, ...state.questions.slice(index + 1)]
        },

        setAnswer: (state, action) => {
            const { name, answer, parent } = action.payload

            const recordIndex = getIndex(state, parent, name)
            state.questions[recordIndex] = { ...state.questions[recordIndex], answer }
        },
        
        setComment: (state, action) => {
            const { name, comment, parent } = action.payload
            const recordIndex = getIndex(state, parent, name)
            state.questions[recordIndex] = {...state.questions[recordIndex], comment }
        },

        setUnit: (state, action) => {
            const { name, parent, unit } = action.payload
            const recordIndex = getIndex(state, parent, name)
            state.questions[recordIndex] = {...state.questions[recordIndex], unit }
        },

        addReason: (state, action) => {
            const { name } = action.payload
            if(!state.questions.find(question => question.name === name)){
                const reasonAdded = { name, type: "bool", options: ["oui", "non"], hasChildren: true}
                state.questions.push(reasonAdded)
            } 
        },

        addId: (state, action) => {
            state.id = action.payload
        },

        setMultipleAnswers: (state, action) => {
            const data = action.payload
            data.forEach((element, index) => {
                const { name, answer, parent, unit, comment, hasChildren } = element
                const recordIndex = getIndex(state, parent, name)
                if(recordIndex === -1){
                    const previousElement = data[index-1]
                    const previousRecordIndex = getIndex(state, previousElement?.parent, previousElement?.name)
                    state.questions.splice(previousRecordIndex+1, 0, { ...element, parent: previousElement?.name, type:"text" })
                } else {
                    state.questions[recordIndex] = { ...state.questions[recordIndex], answer,  unit, comment, hasChildren }
                }
                
            })
        }
    }
})

export const { resetState, setAnswer, eraseRecordElement, addAnswer, createQuestionsArray, setComment, changeQuestionsSequence, setCurrentReason, setUnit, addReason, addId, setMultipleAnswers } = recordSlice.actions
export default recordSlice.reducer