import { bool, radio, checkbox, text, range, date, number } from '../../constants/inputTypes';
import { localisation, which, travel, position, drugUsageQuantity, medicineQuantity } from './other';

// faire une fonction "formulation" globale qui remplacera la fonction formulation dans chaque 
// question pour tous les types de questions (radio, checkbox, text...) si une question a des enfants
// qui ont une réponse, alors cela doit se trouver dans le state, et la question ne doit pas (forcément)
// se retrouver dans le texte mais plutôt ses enfants qui répondront à cette question de façon plus précise

// chaque name doit faire référence à ses parents dans le state

// chaque name et chaque option doit avoir des explications, si nécessaire, et ces explications 
// devront être stockées dans un autre objet. ex : si "procubitus" est rendu : on doit avoir une 
// fonction qui va scanner tout le texte et qui va ajouter un tooltip contenant des explications 

// convertir toutes ces données en JSON qui seront stockées dans une base de données

// ajouter dans ce JSON le nombre de fois où la plainte a été cliquée et le nombre de fois où elle
// a été complétée et la dernière fois où elle a été complétée (ces données resteront dans le 
// backend et ne seront pas transmises dans le frontend)

// options devra plutôt être un dictionnaire contenant l'item en key et le type de mot 
// (ex : adjectif, féminin, pluriel) en value... ou alors constituer ce dictionnaire séparément 
// et y faire appel de la même façon que pour les explications (cfr ci-dessus)

// ajouter pour chaque "isReason === true" les antécédents pertinents (+ faire une liste non 
// exhaustive des antécédents pertinents les plus fréquents pour chaque type de plainte) 

// ajouter pour chaque "isReason === true" un item permettant de dire si les plaintes sont 
// encore présentes au moment de l'anamnèse. Si non, permettre de dire combien de temps cela 
// a duré

// le parent de la question devra soit être la question-mère elle-même si la réponse à cette 
// dernière est "oui", soit la réponse à la question-mère dans les autres cas (si la réponse est,
// par exemple, "des médicaments")

// ajouter pour chaque checkbox une option "autre" qui permettra de cocher une autre option sans 
// devoir déployer les commentaires

/*
const élémentAnamnestiqueÀRendreDansRedux = {
    name: "",
    frenchName: "", // éventuellement à constituer dans objet séparé (traducteur automatique)
    synonyms: [], // éventuellement à constituer dans objet séparé (dictionnaire des synonymes en anglais)
    frenchSynonyms: [], // éventuellement à constituer dans objet séparé (dictionnaire des synonymes en français)
    type: radio,
    isReason: true,
    semanticLinkToParents: true,
    parent: "",
    children: [],
    options: [],
    isAnswered: true,
    answer: "",
    comment: "",
    numberOfRenders: 0, // uniquement dans la database
    numberOfAnswers: 0, // uniquement dans la database
    lastRender: new Date(), // uniquement dans la database
    lastAnswer: new Date(), // uniquement dans la database
}
// formulation sera en-dehors de l'objet. Peut-être qu'il faudra ajouter un item (key+value) pour aider dans la formulation
// (ex : si name = "abdominal pain" alors frenchName sera "douleur abdominale" et si l'enfant est "irradiation" alors il 
// faudra peut-être un/des mot(s) de liaison entre douleur abdominale et irradiation et la réponse)

*/

// faire composant Range (à la place de Number) avec image à superposer au range : par exemple 
// échelle de Bristol pour la constipation et la diarrhée, ou échelle EVA pour l'intensité de la douleur
// ex : rajouter par la suite image avec échelle de consistance des selles (échelle de Bristol) 
// ex https://resize.prod.docfr.doc-media.fr/r/647,431,center-middle,ffffff,smartcrop/ext/eac4ff34/content/2022/7/23/la-consistance-variable-des-selles-54a31739e96e69f7.jpeg

// limiter le nombre d'enfants qui se suivent dans le texte pour éviter les phrases à rallonge

// ajouter possibilité de déterminer une durée. Exemple : dernières selles (dans élément constipation) 
// sont un événement ponctuel, donc pas besoin de durée, mais voyage à l'étranger doit avoir un début 
// et une fin. Idem pour la douleur abdominale par exemple, elle peut être actuellement résolue, donc 
// il faudra donner à l'utilisateur la possibilité d'encoder que la douleur est encore actuellement
// présente ou qu'elle est terminée depuis telle ou telle date. Il faudra construire des inputs de type
// interval (comme pour les douleurs ou les voyages) et punctual (comme pour les dernières selles)

// ajouter la possibilité de dupliquer l'anamnèse et l'examen clinique lorsqu'ils concernent des éléments pairs
// latéralisés (douleur cheville gauche ou droite)

// gérer plus généralement la latéralisation

// gérer les éléments redondants comme par exemple en orthopédie ou en neurologie où on examine la 
// force segmentaire bilatéralement pour la flexion de la hanche, l'extension de la hanche, l'abduction
// de la hanche etc... à faire deux fois car bilatéral (ajouter option (key-value) latéralisé: true,
// avec composants adaptés ?)


export const dysphagia = [
    {
        name : "niveau",
        isReason: false,
        type: radio,
        options: ["haut", "moyen", "bas"],
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.parent} ${state.answer === "haut" ? "haute" : state.answer + state.answer.slice(-1) + "e"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "caractère",
        isReason: false,
        type: checkbox,
        options: ["intermittent", "progressif", "brutal"],
        semanticLinkToParents : true,
        formulation : (state) => {
            const { answer, parent } = state
            if(answer?.length > 0){
                let sentence = `${parent} de caractère `;
                answer.forEach((a, i) => {
                    sentence += a
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })

                return sentence
            }
            return ""
        }
    },
    {
        name : "se manifeste pour",
        isReason: false,
        type: checkbox,
        options: ["solides", "liquides"],
        semanticLinkToParents : true,
        formulation : (state) => {
            const { answer, parent } = state
            if(answer?.length > 0){
                let sentence = `${parent} pour `;
                answer.forEach((a, i) => {
                    sentence += "les " + a
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })
                return sentence
            }
            return ""
        }
    },
    {
        name : "signes de reflux",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de signe de reflux" : "présence de signes de reflux"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "sialorrhée",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de sialorrhée" : "présence de sialorrhée"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]

export const abdominalRiskFactors = [
    {
        name : "tabagisme",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: drugUsageQuantity, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de tabagisme" : "tabagisme"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "consommation d'alcool",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: drugUsageQuantity, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de consommation d'alcool" : "consommation d'alcool"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "aspirine",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: medicineQuantity, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de prise d'aspirine" : "prise d'aspirine"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "anti-inflammatoires non stéroïdiens",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: medicineQuantity, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de prise d'anti-inflammatoires non stéroïdiens" : "prise d'anti-inflammatoires non stéroïdiens"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "corticostéroïdes",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: medicineQuantity, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de prise de corticostéroïdes" : "prise de corticostéroïdes"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "biphosphonates",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: medicineQuantity, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de prise de biphosphonates" : "prise de biphosphonates"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "infection connue à helicobacter pylori",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas d'infection connue à helicobacter pylori" : "infection connue à helicobacter pylori"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "autres facteurs de risque",
        isReason: false,
        type: text,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]

export const nausea = [
    {
        name : "fréquence",
        isReason: false,
        type: number,
        unit: ["/h", "/j", "/sem", "/mois"], 
        min: 0, 
        max: 50,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.parent} dont la fréquence est d'environ ${state.answer} fois${state?.unit}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "plus fréquente le matin",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} qui n'est pas plus fréquente le matin` : `${state.parent} qui est plus fréquente le matin`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "en relation avec les repas",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} qui n'est pas en relation avec les repas` : `${state.parent} qui est en lien avec les repas`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "éructations",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `pas d'éructations` : `présence d'éructations`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "médication prise pour soulager",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} n'ayant pas poussé le/la patient.e à prendre des médicaments pour être soulagé.e` : `${state.parent} ayant poussé le/la patient.e à prendre des médicaments pour être soulagé.e`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "médication potentiellement causale",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} n'ayant pas de médication potentiellement causale` : `${state.parent} ayant pu être causé(e) par une médication`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },   
    {
        name : "prise d'aliments suspects",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} sans d'aliment causal suspect` : `${state.parent} potentiellement provoquée par des aliments`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },  
    {
        name : "vaccination pertinente",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `vaccination pertinente : ${state.answer}.` : null
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]

export const vomiting = [
    {
        name : "fréquence",
        isReason: false,
        type: number,
        unit: ["/h", "/j", "/sem", "/mois"], 
        min: 0, 
        max: 50,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `fréquence des ${state.parent}s d'environ ${state.answer} fois${state?.unit}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "plus fréquent le matin",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} pas plus fréquent le matin` : `${state.parent} plus fréquent le matin`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "en relation avec les repas",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} n'est pas en relation avec les repas` : `${state.parent} est en lien avec les repas`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "aspect",
        isReason: false,
        type: checkbox,
        options: ["de liquide gastrique", "alimentaire", "teinté de sang", "d'hématémèse", "bilieux", "noirâtre"],
        semanticLinkToParents : true,
        formulation : (state) => {
            const { answer, parent } = state
            if(answer?.length > 0){
                let sentence = `${parent} ayant un aspect `;
                answer.forEach((a, i) => {
                    sentence += a
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })

                return sentence
            }
            return ""
        }
    },
    {
        name : "médication prise pour soulager",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} n'ayant pas poussé le/la patient.e à prendre des médicaments pour être soulagé.e` : `${state.parent} ayant poussé le/la patient.e à prendre des médicaments pour être soulagé.e`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "médication potentiellement causale",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} n'ayant pas de médication potentiellement causale` : `${state.parent} ayant pu être causé(e) par une médication`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },   
    {
        name : "prise d'aliments suspects",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} sans d'aliment causal suspect` : `${state.parent} potentiellement provoquée par des aliments`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },  
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]

export const abdominalPain = [
    {
        name : "traumatisme",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} non traumatique` : `${state.parent} d'origine traumatique`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "localisation (subdivision)",
        isReason: false,
        type: radio,
        options: ["segments", "quadrants"],
        semanticLinkToParents : true,
        children: [
            {element: localisation.abdomen.segments, condition: "segments"}, 
            {element: localisation.abdomen.quadrants, condition: "quadrants"}
        ],
        formulation : (state) => ``
    },
    {
        name : "intensité (EVA)",
        isReason: false,
        type: number,
        unit: "/10",
        min: 0, 
        max: 10,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.parent} dont l'intensité est évaluée à ${state.answer}/10 d'EVA.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "caractère",
        isReason: false,
        type: checkbox,
        options: ["crampes", "coliques", "continue", "intermittente", "pointe", "brûlure"],
        semanticLinkToParents : true,
        formulation : (state) => {
            const { answer, parent } = state;
            
            const determinant = {
                crampes: "des ",
                coliques: "des ",
                continue: "",
                intermittente: "",
                pointe: "une ",
                brûlure: "une ",
            }

            if(answer?.length > 0){
                let sentence = `${parent} décrite comme `;
                answer.forEach((a, i) => {
                    sentence += determinant[a];
                    sentence += a;
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })

                return sentence
            }
            return ""
        }
    },
    {
        name : "irradiation",
        isReason: false,
        type: checkbox,
        options: ["dos", "lombaire gauche", "lombaire droite", "scrotum", "thorax", "épaule gauche", "épaule droite", "cuisse gauche", "cuisse droite"],
        semanticLinkToParents : true,
        formulation : (state) => {
            const { answer, parent } = state;
            
            const determinant = {
                "dos": "le ",
                "lombaire gauche": "la région ",
                "lombaire droite": "la région ",
                "scrotum": "le ",
                "thorax": "le ",
                "épaule gauche": "l'",
                "épaule droite": "l'",
                "cuisse gauche": "la ",
                "cuisse droite": "la ",
            }

            if(answer?.length > 0){
                let sentence = `${parent} irradiant dans `;
                answer.forEach((a, i) => {
                    sentence += determinant[a];
                    sentence += a;
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })

                return sentence
            }
            return ""
        }
    },
    {
        name : "facteurs aggravant la douleur",
        isReason: false,
        type: checkbox,
        options: ["position", "alimentation", "médicament(s)", "vomissements", "selles"], 
        semanticLinkToParents : true,
        children: [
            {
                element: position, 
                condition: "position",
            },
            {
                element: which, 
                condition: "médicament(s)",
            },

        ],
        formulation : (state) => {
            const { answer, parent } = state;
            
            const determinant = {
                "médicament(s)": "par certains ",
                "vomissements": "par les ",
                "selles": "par les ",
                "alimentation": "par l'",
                "position": "en fonction de la ",
            }

            if(answer?.length > 0){
                let sentence = `${parent} aggravée `;
                answer.forEach((a, i) => {
                    sentence += determinant[a];
                    sentence += a;
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })

                return sentence
            }
            return ""
        }
    },
    {
        name : "facteurs soulageant la douleur",
        isReason: false,
        type: checkbox,
        options: ["position", "alimentation", "médicament(s)", "vomissements", "selles"], 
        semanticLinkToParents : true,
        children: [
            {
                element: position, 
                condition: "position",
            },
            {
                element: which, 
                condition: "médicament(s)",
            },

        ],
        formulation : (state) => {
            const { answer, parent } = state;
            
            const determinant = {
                "médicament(s)": "par certains ",
                "vomissements": "par les ",
                "selles": "par les ",
                "alimentation": "par l'",
                "position": "en fonction de la ",
            }

            if(answer?.length > 0){
                let sentence = `${parent} aggravée `;
                answer.forEach((a, i) => {
                    sentence += determinant[a];
                    sentence += a;
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })

                return sentence
            }
            return ""
        }
    },
    { 
        name: "répercussions", 
        isReason: false,
        type: checkbox, 
        semanticLinkToParents : true,
        options: ["empêche de dormir", "empêche de travailler"], 
        formulation : (state) => {
            const { answer, parent } = state;

            if(answer?.length > 0){
                let sentence = `${parent} qui `;
                answer.forEach((a, i) => {
                    sentence += a;
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })

                return sentence
            }
            return ""
        }
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]

export const constipation = [
    {
        name : "fréquence",
        isReason: false,
        type: number,
        unit: ["/j", "/sem", "/mois"], 
        min: 0, 
        max: 10,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `fréquence des selles d'environ ${state.answer} fois${state?.unit}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    { 
        name: "dernières selles", 
        isReason: false,
        semanticLinkToParents : true,
        type: date,
        formulation : (state) => {

            const { precision, unit, time, comment, answer } = state;

            let formattedDate;

            switch(unit){
                case "minute":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 17).replace(",", " à")}` : `le ${new Date(answer).toLocaleString("en-GB").slice(0, 17).replace(",", " aux alentours de ")}`;
                    break;

                case "heure":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 14).replace(",", " à")}h` : `le ${new Date(answer).toLocaleString("en-GB").slice(0, 14).replace(",", " aux alentours de ")}h`;                    
                    break;

                case "jour":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}` : `aux alentours du ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}`;                    
                    break;

                case "semaine":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}` : `aux alentours du ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}`;                    
                    break;

                case "mois":
                    formattedDate = precision === "précis" ? `le ${new Date(answer).toLocaleString("en-GB").slice(0, 10)}` : `en ${new Intl.DateTimeFormat('fr-FR', { month: 'long'}).format(new Date(answer))} ${new Date(answer).getFullYear()}`;                    
                    break;

                case "année":
                    formattedDate = precision === "précis" ? `en ${new Intl.DateTimeFormat('fr-FR', { month: 'long'}).format(new Date(answer))} ${new Date(answer).getFullYear()}` : `en ${new Date(answer).getFullYear()}`;                    
                    break;

                default:
                    formattedDate = new Date(answer).toLocaleString("en-GB").slice(0, 17);
            }

            return answer ? (
                `avec dernières selles ${formattedDate}, il y a donc ${precision === "précis" ? "" : "environ "}${time} ${unit}${(time > 1 && unit !== "mois") ? "s" : ""}${comment ?(". " + comment) : ""}`
                ) : null
        },
    },
    {
        name : "consistance (échelle de Bristol)",
        isReason: false,
        type: number,
        unit: "/7",
        min: 0, 
        max: 7,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `selles dont la consistance est gradée à ${state.answer}/7 sur l'échelle de Bristol.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "quantité",
        isReason: false,
        type: radio,
        options: ["petite", "normale", "grande"],
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `selles en quantité qualifiée comme ${state.answer}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },   
    {
        name : "diarrhées intercurrentes",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de diarrhées intercurrentes" : "présence de diarrhées intercurrentes"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "opioïdes",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de prise d'opioïdes" : "prise d'opioïdes"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]

export const diarrhea = [
    {
        name : "fréquence habituelle des selles",
        isReason: false,
        type: number,
        unit: ["/j", "/sem", "/mois"], 
        min: 0, 
        max: 10,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `fréquence habituelle des selles d'environ ${state.answer} fois${state?.unit}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "fréquence actuelle des selles",
        isReason: false,
        type: number,
        unit: ["/j", "/sem", "/mois"], 
        min: 0, 
        max: 10,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `fréquence actuelle des selles d'environ ${state.answer} fois${state?.unit}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "réveillent la nuit",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "les diarrhées ne causent pas de réveil la nuit" : "les diarrhées réveillent le/la patient.e la nuit"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "consistance (échelle de Bristol)",
        isReason: false,
        type: number,
        unit: "/7",
        min: 0, 
        max: 7,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `dont la consistance est gradée à ${state.answer}/7 sur l'échelle de Bristol.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "quantité",
        isReason: false,
        type: radio,
        options: ["petite", "normale", "grande"],
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `selles en quantité qualifiée comme ${state.answer}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },   
    { 
        name: "aspect", 
        isReason: false,
        type: checkbox, 
        semanticLinkToParents : true,
        options: ["rectorragie", "teintées de sang", "méléna", "stéatorrhée", "glaireuse"], 
        formulation : (state) => {
            const { answer, parent } = state;

            if(answer?.length > 0){
                let sentence = `${parent} `;
                const link = {
                    "rectorragie": "avec rectorragies", 
                    "teintées de sang": "teintée de sang", 
                    "méléna": "mélénique", 
                    "stéatorrhée": "avec stéatorrhée", 
                    "glaireuse": "glaireuse",
                }
                answer.forEach((a, i) => {
                    sentence += link[a];
                    if(i === answer.length - 1){
                        sentence += `.${state?.comment ?(" " + state.comment + ".") : ""}`
                    } else if (i === answer.length - 2){
                        sentence += " et "
                    } else {
                        sentence += ", "
                    }
                })

                return sentence
            }
            return ""
        }
    },
    {
        name : "voyage à l'étranger",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: travel, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de voyage récent à l'étranger" : "voyage récent à l'étranger"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "contage",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de contage" : "contage"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "antibiotiques",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas d'antibiothérapie récente" : "antibiothérapie récente"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "laxatifs",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de prise récente de laxatifs" : "prise récente de laxatifs"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]

export const jaundice = [
    {
        name : "voyage à l'étranger",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: travel, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de voyage récent à l'étranger" : "voyage récent à l'étranger"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "consommation d'alcool",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: drugUsageQuantity, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de consommation d'alcool" : "consommation d'alcool"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "drogues intraveineuses",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: drugUsageQuantity, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas d'usage de drogues intraveineuses" : "usage de drogues intraveineuses"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "transfusion sanguine",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "patient.e n'a jamais eu de transfusion sanguine" : "patient.e a déjà été transfusé"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "contage",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de contage" : "contage"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "prurit",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de prurit" : "présence de prurit"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "médicaments",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas de prise de médicaments" : "prise de médicaments"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "antécédents familiaux de maladie hépatique",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? "pas d'antécédents familiaux de maladie hépatique" : "antécédents familiaux de maladie hépatique"}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]