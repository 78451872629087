import { bool, radio, checkbox, text, range, date, number } from '../../constants/inputTypes';
import { localisation, which, travel, position, drugUsageQuantity, medicineQuantity, twist, where } from './other';
import { fever } from "./general"

export const anklePain = [
    {
        name : "traumatisme",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: twist, condition: "oui"}, {element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} non traumatique` : `${state.parent} d'origine traumatique`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    localisation.ankleAndFoot[0],
    {
        name : "intensité (EVA)",
        isReason: false,
        type: number,
        unit: "/10",
        min: 0, 
        max: 10,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.parent} dont l'intensité est évaluée à ${state.answer}/10 d'EVA.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "impotence fonctionnelle",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `pas d'impotence fonctionnelle` : `impotence fonctionnelle`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "médication prise pour soulager",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} n'ayant pas poussé le/la patient.e à prendre des médicaments pour être soulagé.e` : `${state.parent} ayant poussé le/la patient.e à prendre des médicaments pour être soulagé.e`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]


export const backPain = [
    {
        name : "traumatisme",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: twist, condition: "oui"}, {element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} non traumatique` : `${state.parent} d'origine traumatique`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    localisation.back[0],
    {
        name : "intensité (EVA)",
        isReason: false,
        type: number,
        unit: "/10",
        min: 0, 
        max: 10,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.parent} dont l'intensité est évaluée à ${state.answer}/10 d'EVA.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "irradiation",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: where, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `pas d'irradiation` : `douleur irradiante`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "impotence fonctionnelle",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `pas d'impotence fonctionnelle` : `impotence fonctionnelle`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "médication prise pour soulager",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : true,
        children: [{element: which, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `${state.parent} n'ayant pas poussé le/la patient.e à prendre des médicaments pour être soulagé.e` : `${state.parent} ayant poussé le/la patient.e à prendre des médicaments pour être soulagé.e`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "perte de force",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        children: [{element: where, condition: "oui"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `pas de ${state.name}` : `${state.name}`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "trouble de la sensibilité",
        isReason: false,
        type: radio,
        options: ["non", "anesthésie", "dysesthésies", "paresthésies"],
        semanticLinkToParents : false,
        children: [{element: where, condition: "anesthésie"}, {element: where, condition: "dysesthésies"}, {element: where, condition: "paresthésies"}],
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `pas de trouble de la sensibilité` : `présence d${state.answer.charAt(0) === "a" ? "'une " : "e "}${state.answer}`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "trouble de la continence",
        isReason: false,
        type: radio,
        options: bool,
        semanticLinkToParents : false,
        formulation : (state) => state?.answer ? `${state.answer === "non" ? `pas de ${state.name}` : `${state.name}`}.${state?.comment ?(" " + state.comment + ".") : ""}` : null
    },
    {
        name : "autres commentaires",
        isReason: false,
        type: text,
        semanticLinkToParents : true,
        formulation : (state) => state?.answer ? `${state.answer}` : null
    },
]