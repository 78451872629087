import { useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Tooltip } from 'react-tooltip'

import { handleNavbarClick } from "../app/slices/navbarSlice"
import { resetState } from '../app/slices/recordSlice'

import { MdOutlineSearch, MdOutlineQuestionAnswer } from "react-icons/md"
import { GrTextAlignLeft } from "react-icons/gr"
import { RxTable } from "react-icons/rx"

import CheckDoctorIcon from '../icons/CheckDoctorIcon'
import Searchbar from './Searchbar'
import SettingsMenu from './SettingsMenu'


const Navbar = () => {
	const [focus, setFocus] = useState("")
	const { screenWidth } = useSelector(state => state.screenSize)
	const { currentReason } = useSelector(state => state.record)
	const { componentDisplayed } = useSelector(state => state.navbar)

	const dispatch = useDispatch()
	
	const buttons = [
		{
			name: "questions",
			french: "Questions",
			icon: <MdOutlineQuestionAnswer size="20px" />
		},
		{
			name: "text",
			french: "Text",
			icon: <GrTextAlignLeft size="20px" />
		},
		{
			name: "table",
			french: "Tableau",
			icon: <RxTable size="20px" />
		},
	]

	const handleClick = (name) => {
		dispatch(handleNavbarClick(name))
	}

	const handleCheckIconClick = () => {
		dispatch(resetState())
	}

  	return (
    	<div className='flex px-4 sm:px-6 justify-between relative h-14 items-center bg-slate-100 gap-4 shadow' >
			{focus 
				? <div className="fixed top-0 left-0 h-full w-full z-20 bg-gray-900 opacity-50" onClick={() => setFocus("")}></div> 
				: null
			}

			<div className='flex gap-7 sm:gap-12 items-center w-2/3 lg:w-1/3'>
				<div 
					data-tooltip-id="check" 
					data-tooltip-content="Page d'accueil" 
					className='hover:bg-light-gray' 
					style={{ borderRadius: "2rem" }} 
					onClick={handleCheckIconClick}
				>
					<CheckDoctorIcon size='xl' />
					<Tooltip id="check" place="top" effect="solid" />
				</div>
				
				{focus !== "settingsMenu"
					? <div className='w-40 sm:w-60 md:w-80' style={{ zIndex : focus === "searchbar" ? 50 : 0 }} >
						<Searchbar focus={focus} setFocus={setFocus} />
					</div>

					: <div className='text-xl text-gray-400 rounded-full bg-white p-2'><MdOutlineSearch /></div>
				}	
			</div>

			{screenWidth >= 1024 && currentReason
				? <div className='flex gap-4 sm:gap-6 place-content-center w-1/3'>
					{buttons.map(({ name, french, icon }) => 
						<button
							key={name}
							data-tooltip-id={name} 
							data-tooltip-content={french} 
							className='hover:bg-light-gray rounded-full relative p-2' 
							style={{ borderRadius: "2rem", backgroundColor: componentDisplayed === name ? "white" : "rgb(241 245 249)" }} 
							onClick={() => handleClick(name)}
						>
							{icon}
							<Tooltip id={name} place="top" effect="solid" />
						</button>
					)}

				</div>
				: null
			}
			
			<div className='flex w-1/2 lg:w-1/3 justify-end' style={{ zIndex : focus === "settingsMenu" ? 50 : 0 }} >
			{screenWidth < 1024 && currentReason
				? <div className='flex sm:gap-6 place-content-center mr-2 sm:mr-10'>
					{buttons.map(({ name, french, icon }) => 
						<button
							key={name}
							data-tooltip-id={name} 
							data-tooltip-content={french} 
							className='hover:bg-light-gray rounded-full relative p-2' 
							style={{ borderRadius: "2rem", backgroundColor: componentDisplayed === name ? "white" : "rgb(241 245 249)" }} 
							onClick={() => handleClick(name)}
						>
							{icon}
							<Tooltip id={name} place="top" effect="solid" />
						</button>
					)}

				</div>
				: null
			}
				<SettingsMenu focus={focus} setFocus={setFocus} />
			</div>
		</div>
  	)
}


export default Navbar