import { apiSlice } from "./apiSlice"

export const searchbarApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSynonyms: builder.mutation({
            query: () => ({
                url: `/searchbar`,
                method: "GET",
            })
        }),
        postClick: builder.mutation({
            query: (synonym) => ({
                url: `/searchbar`,
                method: "PUT",
                body: { synonym }
            })
        }),
    })
})

export const { useGetSynonymsMutation, usePostClickMutation } = searchbarApiSlice