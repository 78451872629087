import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentThemeColor: "#1A97F5",
    currentThemeMode: "Light",
    displayThemeSettings: false,
}

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setMode: (state, action) => {
            state.currentThemeMode = action.payload;
            localStorage.setItem("themeMode", action.payload);
        },
        setColor: (state, action) => {
            state.currentThemeColor = action.payload;
            localStorage.setItem("colorMode", action.payload);
        },
        changeThemeSettings: (state, action) => {
            state.displayThemeSettings = action.payload;
        }
    }
})

export const { setMode, setColor, changeThemeSettings } = themeSlice.actions;
export default themeSlice.reducer;